import React from 'react';
import { RiAlertLine } from 'react-icons/ri';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { InputCheckBox } from '@itandi/itandi-bb-ui';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import { convertDateWithNull } from '~/utils/dateUtils';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';

export const OwnersEditorBlockAntisocialCheck: React.FC = () => {
  const { formState, setValue, watch } = useFormContext<OwnerForEdit>();
  const { errors } = formState;

  return (
    <FormBlock caption="反社チェック情報" captionIcon={<RiAlertLine />}>
      <FormBlockRow
        errorMessage={errors.antisocialCheckDone?.message}
        label="反社チェック実施可否"
      >
        <InputCheckBox
          checked={watch('antisocialCheckDone')}
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setValue('antisocialCheckDone', e.target.checked);
            // falseに変わる時、チェック実施日も空にしないと、データとして反社チェックされていることになってしまう
            if (!e.target.checked) {
              setValue('antisocialCheckDate', '');
            }
          }}
        />
      </FormBlockRow>

      {watch('antisocialCheckDone') && (
        <FormBlockRow
          errorMessage={errors.antisocialCheckDate?.message}
          label="反社チェック実施日"
        >
          <InputDate
            onChange={(date: Readonly<Date> | null): void => {
              setValue(
                'antisocialCheckDate',
                date === null ? '' : date.toString()
              );
            }}
            selected={convertDateWithNull(watch('antisocialCheckDate'))}
          />
        </FormBlockRow>
      )}
    </FormBlock>
  );
};
