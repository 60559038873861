import React from 'react';
import { ContractsListTableHeader } from '~/components/specifics/contracts/components/List/Table/Header';
import { ContractsListTableBody } from '~/components/specifics/contracts/components/List/Table/Body';
import { TableContainer } from '~/components/common/Table/Container';

export const ContractsListTable: React.FC = () => (
  <TableContainer>
    <ContractsListTableHeader />
    <ContractsListTableBody />
  </TableContainer>
);
