import React from 'react';
import { useRouter } from 'next/router';
import { ReportTemplate } from '~/models/reportTemplate';
import { Button } from '@itandi/itandi-bb-ui';
import { getShowUrl as getExcelShowUrl } from '~/utils/api/resources/contracts/reportTemplates/sheetExcelExporter';
import { getShowUrl as getPdfShowUrl } from '~/utils/api/resources/contracts/reportTemplates/sheetPdfExporter';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { show, destroy } from '~/utils/api/resources/reportTemplate';
import { KeyedMutator } from 'swr';
import { TableContainer } from '../../Table/Container';
import { TableHead } from '../../Table/Head';
import { TableRow } from '../../Table/Row';
import { TableHeader } from '../../Table/Header';
import { TableBody } from '../../Table/Body';
import { TableData } from '../../Table/Data';

type TemplatesListTableProps = {
  isExport: boolean;
  reportTemplates: ReadonlyArray<ReportTemplate>;
  mutate?: KeyedMutator<ReadonlyArray<ReportTemplate>>;
};

export const TemplatesListTable: React.FC<
  Readonly<TemplatesListTableProps>
> = ({ isExport, reportTemplates, mutate }) => {
  const handleFileDelete = async (reportTemplateId: number): Promise<void> => {
    // eslint-disable-next-line no-alert
    if (window.confirm('本当にファイル削除しても良いですか？')) {
      const response = await destroy(reportTemplateId);
      if (isSuccess(response)) {
        mutate?.();
        toast.success('ファイルを削除しました');
        return;
      }
      toast.error('ファイル削除に失敗しました');
    }
  };

  const router = useRouter();

  const handleTemplateDownload = async (
    id: Readonly<number>,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    event: React.MouseEvent<HTMLAnchorElement>
  ): Promise<void> => {
    event.preventDefault();

    const response = await show(Number(id));
    if (isSuccess(response) && response.data?.endpoint != null) {
      await router.push(response.data.endpoint);
    } else {
      await router.push('/404');
    }
  };
  const { id = null } = router.query;

  const onClickExcel = React.useCallback(
    (reportTemplateId: number) => {
      window.open(getExcelShowUrl(Number(id), reportTemplateId), '_blank');
    },
    [id]
  );
  const onClickPdf = React.useCallback(
    (reportTemplateId: number) => {
      window.open(getPdfShowUrl(Number(id), reportTemplateId), '_blank');
    },
    [id]
  );

  return (
    <TableContainer>
      <TableHead>
        <TableRow>
          <TableHeader width={38}>タイトル</TableHeader>
          <TableHeader width={38}>ファイル名</TableHeader>
          {isExport ? (
            <>
              <TableHeader width={12} />
              <TableHeader width={12} />
            </>
          ) : (
            <TableHeader width={24} />
          )}
        </TableRow>
      </TableHead>

      <TableBody>
        {reportTemplates.map((reportTemplate) => (
          <TableRow isInTableBody rowKey={reportTemplate.id}>
            <TableData width={38}>{reportTemplate.title}</TableData>
            <TableData width={38}>
              {/* Enterやspaceキーを押下したときにイベントハンドラを呼び出したくないため */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <a
                onClick={(
                  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
                  event: React.MouseEvent<HTMLAnchorElement>
                ): Promise<void> =>
                  handleTemplateDownload(reportTemplate.id, event)
                }
                rel="noopener noreferrer"
                role="button"
                style={{ cursor: 'pointer' }}
                tabIndex={0}
                target="_blank"
              >
                {reportTemplate.fileName}
              </a>
            </TableData>
            {isExport ? (
              <>
                <TableData width={12}>
                  <Button
                    height="small"
                    onClick={(): void => onClickExcel(reportTemplate.id)}
                    variant="tertiary"
                  >
                    Excel出力
                  </Button>
                </TableData>
                <TableData width={12}>
                  <Button
                    height="small"
                    onClick={(): void => onClickPdf(reportTemplate.id)}
                    variant="secondary"
                  >
                    Pdf出力
                  </Button>
                </TableData>
              </>
            ) : (
              <TableData width={24}>
                <Button
                  height="small"
                  onClick={(): Promise<void> =>
                    handleFileDelete(reportTemplate.id)
                  }
                  variant="danger"
                >
                  削除
                </Button>
              </TableData>
            )}
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
};
