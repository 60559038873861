import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { ContractWithOwners } from '~/models/contract';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { ContractQuery } from '~/models/contractQuery';
import { convertUrlQueryToContractQuery } from '~/utils/contracts/contractUrlQueryConverter';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

export const paths = {
  index: '/contracts',
  show: '/contracts/:id'
};
type TIndex = () => SwrReturnType<Pageable<ContractWithOwners>>;
type TShow = (id: number | null) => SwrReturnType<ContractWithOwners>;
type ReturnType = Readonly<{
  useIndex: TIndex;
  useShow: TShow;
}>;

export const useContracts = (): ReturnType => {
  const useIndex: TIndex = () => {
    const router = useRouter();
    const query: ContractQuery | null = useMemo(() => {
      if (!router.isReady) {
        return null;
      }
      return convertUrlQueryToContractQuery(router.query);
    }, [router]);
    const { data, mutate, error } = useSWR<Pageable<ContractWithOwners>>(
      query === null ? null : buildUrlWithQuery(paths.index, query)
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  const useShow: TShow = (id: number | null) => {
    const { data, mutate, error } = useSWR<ContractWithOwners>(
      id === null ? null : paths.show.replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex, useShow };
};
