export type Invoice = Readonly<{
  id: number;
  unitType: InvoiceUnitType;
  feeType: InvoiceFeeType;
  amount: number;
}>;

type InvoiceUnitType = 'yen_without_tax' | 'month';

export type InvoiceFeeType =
  | 'new_contract_commission'
  | 'renewal_commission'
  | 'cancellation_commission';

export const invoiceUnitTypeLabels: { [field in InvoiceUnitType]: string } = {
  yen_without_tax: '円(税抜)',
  month: 'カ月分の家賃'
};

export type InvoiceForEdit = Readonly<{
  unitType: InvoiceUnitType | null;
  feeType: InvoiceFeeType;
  amount: number | null;
}>;

export type InvoicesForEdit = Readonly<{
  [field in InvoiceFeeType]: Omit<InvoiceForEdit, 'feeType'>;
}>;

export const defaultValueInvoicesForEdit: InvoicesForEdit = {
  new_contract_commission: { unitType: null, amount: null },
  renewal_commission: { unitType: null, amount: null },
  cancellation_commission: { unitType: null, amount: null }
};

// バックエンドにリクエスト送る時の型を作る関数

export const convertRequestInvoices = (
  invoicesForEdit: InvoicesForEdit
): ReadonlyArray<InvoiceForEdit> => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const invoiceFeeTypes = Object.keys(
    invoicesForEdit
  ) as ReadonlyArray<InvoiceFeeType>;

  return invoiceFeeTypes
    .map((feeType: InvoiceFeeType) => ({
      unitType: invoicesForEdit[feeType].unitType,
      amount: invoicesForEdit[feeType].amount,
      feeType
    }))
    .filter(
      (invoiceForEdit: InvoiceForEdit) =>
        invoiceForEdit.amount && invoiceForEdit.unitType
    );
};

// バックエンドから来たinvociesをフォーム用に変換する関数
export const convertFormInvoices = (
  invoices: ReadonlyArray<Invoice>
): InvoicesForEdit =>
  invoices.reduce(
    (result: InvoicesForEdit, invoice: Invoice): InvoicesForEdit => ({
      ...result,
      [invoice.feeType]: {
        unitType: invoice.unitType,
        amount: Number(invoice.amount)
      }
    }),
    defaultValueInvoicesForEdit
  );
