import React from 'react';
import { OwnersPageTabs } from '~/components/specifics/owners/[id]/components/PageTabs';
import { OwnersContents } from '~/components/specifics/owners/[id]/components/Contents';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import Styles from './index.module.scss';

export type TabMode =
  | 'basic'
  | 'contracts'
  | 'contacts'
  | 'bank_accounts'
  | 'prints'
  | 'files';
export const OwnersContainer: React.FC = () => {
  const [tabMode, setTabMode] = React.useState<TabMode>('basic');
  const handleChangeTabMode = React.useCallback((newTabMode: TabMode): void => {
    setTabMode(newTabMode);
  }, []);
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.TabArea}>
        <OwnersPageTabs
          currentTabMode={tabMode}
          handleChangeTabMode={handleChangeTabMode}
          owner={owner}
        />
      </div>
      <div className={Styles.Contents}>
        <OwnersContents owner={owner} tabMode={tabMode} />
      </div>
    </div>
  );
};
