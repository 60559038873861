import React from 'react';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { getContentOrEmptyLabel } from '~/components/specifics/owners/[id]/components/Contents/Basic';
import { ListItem } from '~/components/common/List/Item';
import Styles from '../index.module.scss';

export const OwnersContentsBasicCorp: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }
  return (
    <>
      <ListItem
        content={
          <div className={Styles.NameWrapper}>
            <div>{owner.corpName}</div>
            {owner.corpNameKana !== null && owner.corpNameKana !== '' && (
              <div>{owner.corpNameKana}</div>
            )}
          </div>
        }
        title="法人名"
      />
      <ListItem
        content={getContentOrEmptyLabel(owner.ceoName)}
        title="法人代表者名"
      />
      <ListItem
        content={getContentOrEmptyLabel(owner.ceoTitle)}
        title="法人代表者役職"
      />
      <ListItem
        content={getContentOrEmptyLabel(owner.corpContactName)}
        title="法人担当者"
      />
      <ListItem
        content={getContentOrEmptyLabel(owner.corpContactNote)}
        title="法人担当者備考"
      />
    </>
  );
};
