import React, { ReactNode } from 'react';
import Link from 'next/link';
import { contractTypeLabels, ContractWithOwners } from '~/models/contract';
import { PATH as CONTRACT_PATH } from '~/pages/contracts/[id]';
import { PATH as OWNER_PATH } from '~/pages/owners/[id]';
import { StatusLabel } from '~/components/common/StatusLabel';
import { displayName, Owner } from '~/models/owner';
import { TextDate } from '~/components/common/Text/Date/index';
import { TableRow } from '~/components/common/Table/Row';
import { TableData } from '~/components/common/Table/Data';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  contract: ContractWithOwners;
}>;

export const ContractsListTableBodyRow: React.FC<Props> = ({
  contract
}: Props) => (
  <TableRow isInTableBody>
    <TableData width={10}>
      {(contract.archived && (
        <StatusLabel variant="danger">解約済み</StatusLabel>
      )) ||
        (contract.contractApproval === null && (
          <StatusLabel>契約準備中</StatusLabel>
        )) || <StatusLabel variant="success">契約中</StatusLabel>}
    </TableData>
    <TableData width={30}>
      <Link href={CONTRACT_PATH(contract.id)}>
        <a>{contract.property ? contract.property.displayName : '未登録'}</a>
      </Link>
    </TableData>
    <TableData width={15}>{contract.storeName}</TableData>
    <TableData width={10}>
      {contractTypeLabels[contract.contractType]}{' '}
    </TableData>
    <TableData isVertical width={15}>
      {contract.owners.map(
        (owner: Owner): ReactNode => (
          <div key={owner.id}>
            <Link href={OWNER_PATH(owner.id)}>
              <a className={Styles.OwnerLink}>{displayName(owner)}</a>
            </Link>
          </div>
        )
      )}
    </TableData>
    <TableData width={30}>
      <TextDate dateStr={contract.startDate} />
      <span className={Styles.TermSeparator}>-</span>
      <TextDate dateStr={contract.endDate} />
    </TableData>
    <TableData width={18}>
      <TextDate dateStr={contract.originContract.startDate} />
    </TableData>
  </TableRow>
);
