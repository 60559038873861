import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import {
  Input,
  FormLabel,
  Button,
  Toggle,
  InputSelect
} from '@itandi/itandi-bb-ui';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import { convertUrlQueryToContractQuery } from '~/utils/contracts/contractUrlQueryConverter';
import { convertDateWithNull, convertQueryStringDate } from '~/utils/dateUtils';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  handleDrawerClose: () => void;
}>;

export const ContractsSearchArea: React.FC<Props> = ({
  handleDrawerClose
}: Props) => {
  const router = useRouter();
  const [query, setQuery] = useState<DeepReadonly<ParsedUrlQuery>>(
    router.query
  );

  const handleChange = (
    key: string,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ): void => {
    if (e.target.value) {
      setQuery({ ...query, [key]: e.target.value });
    } else {
      const { [key]: _, ...newQuery } = query;
      setQuery(newQuery);
    }
  };
  const handleChangeArchived = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.checked) {
      setQuery({ ...query, archived: 'true' });
    } else {
      const { archived: _, ...newQuery } = query;
      setQuery(newQuery);
    }
  };
  const handleChangeDate = (key: string, date: Readonly<Date> | null): void => {
    if (date) {
      setQuery({ ...query, [key]: convertQueryStringDate(date) });
    } else {
      const { [key]: _, ...newQuery } = query;
      setQuery(newQuery);
    }
  };
  const handleSubmit = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    handleDrawerClose();
    const newQuery = convertUrlQueryToContractQuery(query);
    newQuery.page = 1;
    router.push({ query: newQuery });
  };

  return (
    <form className={Styles.SearchArea} onSubmit={handleSubmit}>
      <div className={Styles.FormArea}>
        <FormLabel>管理対象名</FormLabel>
        <Input
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange('propertyName', e)
          }
          value={query.propertyName || ''}
        />
      </div>
      <div className={Styles.FormArea}>
        <FormLabel>契約管理番号</FormLabel>
        <Input
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange('contractKey', e)
          }
          value={query.contractKey || ''}
        />
      </div>
      <div className={Styles.FormArea}>
        <FormLabel>契約開始日</FormLabel>
        <div className={Styles.PeriodWrapper}>
          <InputDate
            className={Styles.DateInput}
            onChange={(date: Readonly<Date> | null): void => {
              handleChangeDate('startDateFrom', date);
            }}
            selected={
              query.startDateFrom
                ? convertDateWithNull(String(query.startDateFrom))
                : null
            }
          />
          〜
          <InputDate
            className={Styles.DateInput}
            onChange={(date: Readonly<Date> | null): void => {
              handleChangeDate('startDateTo', date);
            }}
            selected={
              query.startDateTo
                ? convertDateWithNull(String(query.startDateTo))
                : null
            }
          />
        </div>
      </div>
      <div className={Styles.FormArea}>
        <FormLabel>契約終了日</FormLabel>
        <div className={Styles.PeriodWrapper}>
          <InputDate
            className={Styles.DateInput}
            onChange={(date: Readonly<Date> | null): void => {
              handleChangeDate('endDateFrom', date);
            }}
            selected={
              query.endDateFrom
                ? convertDateWithNull(String(query.endDateFrom))
                : null
            }
          />
          〜
          <InputDate
            className={Styles.DateInput}
            onChange={(date: Readonly<Date> | null): void => {
              handleChangeDate('endDateTo', date);
            }}
            selected={
              query.endDateTo
                ? convertDateWithNull(String(query.endDateTo))
                : null
            }
          />
        </div>
      </div>
      <div className={Styles.FormArea}>
        <FormLabel>管理レポート報告月</FormLabel>
        <InputSelect
          fullWidth
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
            handleChange('managementReportingMonth', e)
          }
          value={query.managementReportingMonth || ''}
        >
          <option value="">選択なし</option>
          <option value="1">1月</option>
          <option value="2">2月</option>
          <option value="3">3月</option>
          <option value="4">4月</option>
          <option value="5">5月</option>
          <option value="6">6月</option>
          <option value="7">7月</option>
          <option value="8">8月</option>
          <option value="9">9月</option>
          <option value="10">10月</option>
          <option value="11">11月</option>
          <option value="12">12月</option>
        </InputSelect>
      </div>
      <div className={Styles.ToggleWrapper}>
        <FormLabel>解約済み</FormLabel>
        <Toggle
          checked={query.archived === 'true'}
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChangeArchived(e)
          }
        />
      </div>
      <div className={Styles.FormArea}>
        <FormLabel>自社管理番号</FormLabel>
        <Input
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange('kensakukunForeignSystemKey', e)
          }
          value={query.kensakukunForeignSystemKey || ''}
        />
      </div>
      <Button
        className={Styles.Button}
        height="default"
        type="submit"
        variant="primary"
      >
        検索
      </Button>
    </form>
  );
};
