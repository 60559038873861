import React from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  str: string | null;
  unit?: string;
}>;

export const TextWithUnitString: React.FC<Props> = ({
  str,
  unit = ''
}: Props) => {
  if (str === null || str === '') {
    return <span className={Styles.Empty}>未登録</span>;
  }
  return <span>{`${str}${unit}`}</span>;
};
