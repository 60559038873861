import { postRequest, putRequest } from '~/utils/api/apiHandler';
import { OwnerForEdit } from '~/models/owner';
import { YkResponse } from '~/models/api';

const paths = {
  create: '/owners',
  update: '/owners/:id'
};

export const create = async (
  owner: DeepReadonly<OwnerForEdit>
): Promise<YkResponse<{ id: number }>> => postRequest(paths.create, { owner });

export const update = async (
  owner: DeepReadonly<OwnerForEdit>,
  ownerId: number
): Promise<YkResponse> =>
  putRequest(paths.update.replace(':id', String(ownerId)), { owner });
