import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { RentRoom } from '~/models/rentRoom';

const paths = {
  index: '/kensakukun/rent_buildings/:building_property_id/rent_rooms'
};
type TIndex = (
  buildingPropertyId: number | null
) => SwrReturnType<ReadonlyArray<RentRoom>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useRentRooms = (): ReturnType => {
  const useIndex: TIndex = (buildingPropertyId: number | null) => {
    const { data, error, isValidating } = useSWR<{
      rentRooms: ReadonlyArray<RentRoom>;
    }>(
      buildingPropertyId
        ? paths.index.replace(
            ':building_property_id',
            String(buildingPropertyId)
          )
        : null
    );
    return {
      data: data ? data.rentRooms : null,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
