import { putRequest, deleteRequest } from '~/utils/api/apiHandler';
import { YkResponse } from '~/models/api';
import { SeisanKanrikunContract } from '~/models/seisanKanrikunContract';

const paths = {
  update: '/seisan_kanrikun/contracts/:id',
  destroy: '/seisan_kanrikun/contracts/:id'
};

export const update = async (
  seisanKanrikunContract: SeisanKanrikunContract,
  contractId: number
): Promise<YkResponse> =>
  putRequest(paths.update.replace(':id', String(contractId)), {
    skContractId: seisanKanrikunContract.id
  });

export const destroy = async (contractId: number): Promise<YkResponse> =>
  deleteRequest(paths.destroy.replace(':id', String(contractId)));
