import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/contracts/new/Page';

export const PATH = '/contracts/new';

const NewContract: NextPage = () => (
  <>
    <HeadsYkHead title="管理委託契約の新規登録" />
    <Component />
  </>
);

export default NewContract;
