import React, { ReactNode } from 'react';
import { InputSelect, InputRadio } from '@itandi/itandi-bb-ui';
import {
  CustomFieldSetting,
  CustomFieldSettingSelect
} from '~/models/customFieldSetting';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  customFieldSetting: CustomFieldSetting;
}>;

export const ContractsEditorBlockCustomFieldSelect: React.FC<Props> = ({
  customFieldSetting
}: Props) => {
  const { register } = useFormContext<ContractForEdit>();
  if (customFieldSetting.fieldType !== 'select') {
    return null;
  }
  const setting: DeepReadonly<CustomFieldSettingSelect> = customFieldSetting;
  if (setting.possibleValues.length <= 3) {
    return (
      <div className={Styles.RadioGroup}>
        {setting.possibleValues.map(
          (value: string): ReactNode => (
            <InputRadio
              key={value}
              label={value}
              value={value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`customFieldValues.${customFieldSetting.fieldKey}`)}
            />
          )
        )}
      </div>
    );
  }

  return (
    <InputSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(`customFieldValues.${customFieldSetting.fieldKey}`)}
    >
      <option value="">未選択</option>
      {setting.possibleValues.map(
        (value: string): ReactNode => (
          <option key={value} value={value}>
            {value}
          </option>
        )
      )}
    </InputSelect>
  );
};
