import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/contracts/[id]/histories';

export const PATH = (id: number): string => `/contracts/${id}/histories`;

const ContractHistories: NextPage = () => (
  <>
    <HeadsYkHead title="管理委託契約履歴" />
    <Component />
  </>
);

export default ContractHistories;
