import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { SeisanKanrikunOwner } from '~/models/seisanKanrikunOwner';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

const paths = {
  index: '/seisan_kanrikun/owners'
};

type SearchParams = DeepReadonly<{
  ykOwnerId?: number;
  name?: string;
  propertyName?: string;
}>;
type TIndex = (
  params: SearchParams
) => SwrReturnType<ReadonlyArray<SeisanKanrikunOwner>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useSeisanKanrikunOwners = (): ReturnType => {
  const useIndex: TIndex = (params: SearchParams) => {
    const { data, mutate, error, isValidating } = useSWR<
      ReadonlyArray<SeisanKanrikunOwner>
    >(
      params.ykOwnerId || params.name || params.propertyName
        ? buildUrlWithQuery(paths.index, params)
        : null
    );
    return {
      data: data || null,
      mutate,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
