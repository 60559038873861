import React from 'react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { show } from '~/utils/api/resources/contracts/contractFiles/fileDownload';
import { isSuccess } from '~/utils/api/apiHandler';

export const PATH = (id: number, contractFileId: number): string =>
  `/redirect/contracts/${id}/contract_files/${contractFileId}`;

// 管理委託契約に紐づくファイルの実態にリダイレクトするためのページ
const RedireactContractFile: NextPage = () => {
  const router = useRouter();
  const { id, contractFileId } = router.query;

  React.useEffect(() => {
    if (id && contractFileId) {
      (async (): Promise<void> => {
        const response = await show(Number(id), Number(contractFileId));
        if (isSuccess(response) && response.data?.endpoint != null) {
          await router.replace(response.data.endpoint);
        } else {
          await router.replace('/404');
        }
      })();
    }
  }, [id, contractFileId, router]);

  return null;
};

export default RedireactContractFile;
