import React from 'react';
import Link from 'next/link';
import { RiArrowRightSLine } from 'react-icons/ri';
import Styles from './index.module.scss';

export type Setting = Readonly<{
  title: string;
  description: string;
  path: string;
}>;

type Props = Readonly<{
  setting: Setting;
}>;

export const SettingsListMenuItem: React.FC<Props> = ({ setting }: Props) => (
  <Link href={setting.path}>
    <div className={Styles.MenuItem}>
      <div className={Styles.TextWrapper}>
        <div className={Styles.Title}>{setting.title}</div>
        <div className={Styles.Description}>{setting.description}</div>
      </div>
      <RiArrowRightSLine className={Styles.Icon} />
    </div>
  </Link>
);
