import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import { RiPriceTag3Line } from 'react-icons/ri';
import { Input } from '@itandi/itandi-bb-ui';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import { convertDateWithNull } from '~/utils/dateUtils';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';

export const ContractsEditorBlockOriginContractBlock: React.FC = () => {
  const { register, formState, setValue, watch } =
    useFormContext<ContractForEdit>();
  const { errors } = formState;
  return (
    <FormBlock caption="原契約情報" captionIcon={<RiPriceTag3Line />}>
      <FormBlockRow
        errorMessage={errors.originContract?.contractKey?.message}
        label="契約管理番号"
      >
        <Input
          placeholder="例）000001"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('originContract.contractKey')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.originContract?.startDate?.message}
        label="原契約開始日"
        required
      >
        <InputDate
          onChange={(date: Readonly<Date> | null): void => {
            if (date === null) {
              setValue('originContract.startDate', '');
              return;
            }
            setValue('originContract.startDate', date.toString());
          }}
          selected={convertDateWithNull(watch('originContract.startDate'))}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.originContract?.endDate?.message}
        label="原契約終了日"
        required
      >
        <InputDate
          onChange={(date: Readonly<Date> | null): void => {
            if (date === null) {
              setValue('originContract.endDate', '');
              return;
            }
            setValue('originContract.endDate', date.toString());
          }}
          selected={convertDateWithNull(watch('originContract.endDate'))}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.originContract?.terminationDate?.message}
        label="原契約解約日"
      >
        <InputDate
          onChange={(date: Readonly<Date> | null): void => {
            if (date === null) {
              setValue('originContract.terminationDate', '');
              return;
            }
            setValue('originContract.terminationDate', date.toString());
          }}
          selected={convertDateWithNull(
            watch('originContract.terminationDate')
          )}
        />
      </FormBlockRow>
    </FormBlock>
  );
};
