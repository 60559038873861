import React from 'react';
import { TableHead } from '~/components/common/Table/Head';
import { TableRow } from '~/components/common/Table/Row';
import { TableHeader } from '~/components/common/Table/Header';

export const ContractHistoriesListTableHeader: React.FC = () => (
  <TableHead>
    <TableRow>
      <TableHeader width={33}>契約種別</TableHeader>
      <TableHeader width={33}>契約期間</TableHeader>
      <TableHeader width={33}>履歴作成日</TableHeader>
    </TableRow>
  </TableHead>
);
