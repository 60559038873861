import React from 'react';
import { useItandiUserSessions } from '~/hooks/api/useItandiUserSessions';
import { PATH } from '~/pages/contracts/index';
import { useRouter } from 'next/router';
import { create } from '~/utils/api/resources/staff';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { Button } from '@itandi/itandi-bb-ui';
import { GlobalHeader } from '~/components/common/Headers/Global';
import Styles from './index.module.scss';

export const Page: React.FC = () => {
  const { data: itandiUserSession } = useItandiUserSessions().useShow();
  const router = useRouter();

  const createStaff = async (): Promise<void> => {
    const response = await create();
    if (isSuccess(response)) {
      router.push(PATH);
      toast.success('家主管理くんの利用を開始しました');
      return;
    }
    toast.error('家主管理くんの利用を開始できませんでした');
  };

  // itandiUserSessionはページ読み込み時はnullなので、早期リターンでサインアップページの表示を防止
  if (itandiUserSession === null) {
    return null;
  }

  // スタッフレコードが既に存在しているなら/contractsに移動
  if (itandiUserSession?.staff) {
    router.replace(PATH);
  }

  return (
    <>
      <GlobalHeader />
      <div className={Styles.Container}>
        <h1 className={Styles.Title}>家主管理くんを初めてご利用のお客様へ</h1>
        <Button
          className={Styles.Button}
          onClick={(): Promise<void> => createStaff()}
          variant="primary"
        >
          家主管理くんの利用を開始する
        </Button>
      </div>
    </>
  );
};
