import React from 'react';
import Link from 'next/link';
import { contractTypeLabels, Contract } from '~/models/contract';
import { PATH as CONTRACT_HISTORY_PATH } from '~/pages/contracts/[id]/histories/[historyId]/index';
import { displayDate } from '~/utils/dateUtils';
import { ContractHistory } from '~/models/contractHistory';
import { TextDate } from '~/components/common/Text/Date/index';
import { TableBody } from '~/components/common/Table/Body';
import { TableRow } from '~/components/common/Table/Row';
import { TableData } from '~/components/common/Table/Data';
import Styles from '../../../../../components/List/Table/Body/Row/index.module.scss';

type Props = DeepReadonly<{
  contract: Contract;
  contractHistory: ContractHistory;
}>;

export const ContractHistoriesListTableBody: React.FC<Props> = ({
  contract,
  contractHistory
}: Props) => (
  <TableBody isBackGroundColorWhite>
    <TableRow isInTableBody>
      <TableData width={33}>
        {contractTypeLabels[contractHistory.contractType]}
      </TableData>
      <TableData width={33}>
        <TextDate dateStr={contractHistory.startDate} />
        <span className={Styles.TermSeparator}>-</span>
        <TextDate dateStr={contractHistory.endDate} />
      </TableData>
      <TableData width={33}>
        <Link href={CONTRACT_HISTORY_PATH(contract.id, contractHistory.id)}>
          {displayDate(contractHistory.createdAt)}
        </Link>
      </TableData>
    </TableRow>
  </TableBody>
);
