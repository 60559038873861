import React from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  caption?: string;
  captionIcon?: React.ReactNode;
  children: React.ReactNode;
}>;

export const FormBlock: React.FC<Props> = ({
  caption,
  captionIcon,
  children
}: Props) => (
  <div className={Styles.Block}>
    {caption && captionIcon && (
      <div className={Styles.BlockCaptionWrapper}>
        <div className={Styles.BlockCaption}>
          <span className={Styles.BlockIconWrapper}>{captionIcon}</span>
          <span className={Styles.BlockCaptionLabel}>{caption}</span>
        </div>
      </div>
    )}
    <div className={Styles.BlockForms}>{children}</div>
  </div>
);
