import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  title: string;
  content: ReactNode;
}>;

export const ListItem: React.FC<Props> = ({ title, content }: Props) => (
  <div className={Styles.Row}>
    <div className={Styles.Caption}>{title}</div>
    <div className={Styles.Content}>{content}</div>
  </div>
);
