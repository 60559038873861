import React, { ReactNode } from 'react';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ContractsEditorOwnerSelectorModal } from '~/components/specifics/contracts/components/Editor/OwnerSelector/Modal';
import { Owner } from '~/models/owner';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import { ContractsEditorOwnerSelectorSelectedRow } from '~/components/specifics/contracts/components/Editor/OwnerSelector/SelectedRow';
import Styles from './index.module.scss';

export const ContractsEditorOwnerSelector: React.FC = () => {
  const { watch } = useFormContext<DeepReadonly<ContractForEdit>>();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const owners = watch('owners');
  const renderSelectedOwner = React.useCallback(
    (owner: DeepReadonly<Owner>, index: number): ReactNode => (
      <ContractsEditorOwnerSelectorSelectedRow
        key={owner.id}
        index={index}
        owner={owner}
      />
    ),
    []
  );
  return (
    <div>
      {owners.length === 0 && (
        <button
          className={Styles.SelectButton}
          onClick={handleOpen}
          type="button"
        >
          家主を選択
        </button>
      )}
      {owners.length > 0 && (
        <div className={Styles.SelectedList}>
          {owners.map(renderSelectedOwner)}
          <button
            className={Styles.SelectButton}
            onClick={handleOpen}
            type="button"
          >
            家主を追加する
          </button>
        </div>
      )}
      <ContractsEditorOwnerSelectorModal
        handleClose={handleClose}
        isOpen={isOpen}
      />
    </div>
  );
};
