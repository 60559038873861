import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { ContractCollaborationData } from '~/models/contractCollaborationData';

const paths = {
  show: '/seisan_kanrikun/contracts/:contract_id/contract_collaboration_datas'
};
type TShow = (contractId: number) => SwrReturnType<ContractCollaborationData>;
type ReturnType = Readonly<{
  useShow: TShow;
}>;

export const useContractCollaborationData = (): ReturnType => {
  const useShow: TShow = (contractId: number) => {
    const { data, mutate, error } = useSWR<ContractCollaborationData>(
      paths.show.replace(':contract_id', String(contractId))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useShow };
};
