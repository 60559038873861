import React from 'react';
import { TableHead } from '~/components/common/Table/Head';
import { TableRow } from '~/components/common/Table/Row';
import { TableHeader } from '~/components/common/Table/Header';

export const OwnersListTableHeader: React.FC = () => (
  <TableHead>
    <TableRow>
      <TableHeader width={8}>管理番号</TableHeader>
      <TableHeader width={20}>家主名/会社名</TableHeader>
      <TableHeader width={20}>電話番号</TableHeader>
      <TableHeader width={20}>メールアドレス</TableHeader>
      <TableHeader width={20}>管理対象</TableHeader>
      <TableHeader width={8}>担当者</TableHeader>
      <TableHeader width={9}>登録日時</TableHeader>
    </TableRow>
  </TableHead>
);
