import React from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  handleCollaborateModalClose: () => void;
  handleCollaborationDataDisplayModalOpen: () => void;
}>;

export const ContractsCollaborationSeisanKanrikunNewLink: React.FC<Props> = ({
  handleCollaborateModalClose,
  handleCollaborationDataDisplayModalOpen
}: Props) => {
  const handleCloseAndOpenModal = (): void => {
    handleCollaborateModalClose();
    handleCollaborationDataDisplayModalOpen();
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <a className={Styles.Link} onClick={handleCloseAndOpenModal}>
        精算管理くんで管理委託契約を新規登録
      </a>
    </>
  );
};
