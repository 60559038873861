import React from 'react';
import { displayDate } from '~/utils/dateUtils';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  dateStr: string | null;
}>;

export const TextDate: React.FC<Props> = ({ dateStr }: Props) => {
  if (dateStr === null || dateStr === '') {
    return <span className={Styles.Empty}>未登録</span>;
  }
  return <span>{displayDate(dateStr)}</span>;
};
