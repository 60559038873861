import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { StaffWithRoleName } from '~/models/staff';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

type SearchParams = DeepReadonly<{
  page?: number;
  name?: string;
  roleId?: number;
}>;

const paths = {
  index: '/staffs'
};
type TIndex = (
  params: SearchParams
) => SwrReturnType<Pageable<StaffWithRoleName>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useStaffs = (): ReturnType => {
  const useIndex: TIndex = (params: SearchParams) => {
    const { data, mutate, error } = useSWR<Pageable<StaffWithRoleName>>(
      buildUrlWithQuery(paths.index, params)
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex };
};
