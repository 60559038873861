import React from 'react';
import { buildSeisanKanrikunOwnerUrl } from '~/utils/foreignOrigin';
import { useSeisanKanrikunOwners } from '~/hooks/api/useSeisanKanrikunOwners';
import { OwnerWithContracts } from '~/models/owner';
import { destroy } from '~/utils/api/resources/seisanKanrikunOwner';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { OwnersCollaborationModal } from '~/components/specifics/owners/[id]/components/Collaboration/Modal';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { StatusLabelCollaborationLabel } from '~/components/common/StatusLabel/Collaboration/Label';

type Props = DeepReadonly<{
  owner: OwnerWithContracts;
}>;

export const OwnersSeisanKanrikunCollaborationTab: React.FC<Props> = ({
  owner
}: Props) => {
  const { data: seisanKanrikunOwners, mutate } =
    useSeisanKanrikunOwners().useIndex({
      ykOwnerId: owner.id
    });

  const collaboratedSeisanKanrikunOwner =
    seisanKanrikunOwners !== null ? seisanKanrikunOwners[0] : undefined;

  const { isOpen, handleOpen, handleClose } = useOpenHandler();

  // 連携解除
  const handleRemoveCollaborate = async (ownerId: number): Promise<void> => {
    if (!collaboratedSeisanKanrikunOwner) {
      return;
    }
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `精算管理くん上のオーナー ${collaboratedSeisanKanrikunOwner.name}と連携解除して良いですか？`
      )
    ) {
      const response = await destroy(ownerId);
      if (isSuccess(response)) {
        mutate?.();
        toast.success('連携解除に成功しました');
        return;
      }
      toast.error('連携解除に失敗しました');
    }
  };

  return (
    <>
      <StatusLabelCollaborationLabel
        buttonText={collaboratedSeisanKanrikunOwner ? '連携解除' : '連携'}
        externalLink={
          collaboratedSeisanKanrikunOwner
            ? buildSeisanKanrikunOwnerUrl(owner.id)
            : undefined
        }
        onClick={
          collaboratedSeisanKanrikunOwner
            ? (): Promise<void> => handleRemoveCollaborate(owner.id)
            : handleOpen
        }
        productKey="sk"
        text={
          collaboratedSeisanKanrikunOwner
            ? 'オーナー連携済み'
            : 'オーナー未連携'
        }
      />
      <OwnersCollaborationModal
        collaboratedSeisanKanrikunOwnerMutate={mutate}
        handleClose={handleClose}
        isOpen={isOpen}
        owner={owner}
      />
    </>
  );
};
