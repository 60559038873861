import { ContractFile, ContractFileParams } from '~/models/contractFile';
import { YkResponse } from '~/models/api';
import { postRequest, deleteRequest } from '~/utils/api/apiHandler';

const paths = {
  create: '/contracts/:contract_id/contract_files',
  destroy: '/contracts/:contract_id/contract_files/:id'
};

export const create = async (
  contractId: number,
  contractFileParams: ContractFileParams
): Promise<YkResponse<void>> => {
  const formData: FormData = new FormData();
  formData.append('contractFile[title]', contractFileParams.title);
  if (contractFileParams.file) {
    formData.append('contractFile[file]', contractFileParams.file);
  }

  return postRequest(
    paths.create.replace(':contract_id', String(contractId)),
    formData
  );
};

export const destroy = async (
  contractFile: ContractFile
): Promise<YkResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':id', String(contractFile.id))
      .replace(':contract_id', String(contractFile.contractId))
  );
