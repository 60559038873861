import React from 'react';
import { ItandiHeader, ItandiHeaderItem } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { PATH as OWNERS_PATH } from '~/pages/owners';
import { PATH as CONTRACTS_PATH } from '~/pages/contracts';
import { PATH as SETTINGS_PATH } from '~/pages/settings';
import { useItandiAccountSessions } from '~/hooks/api/useItandiAccountSessions';
import { destroy as logout } from '~/utils/api/resources/itandiAccountSession';
import { isSuccess } from '~/utils/api/apiHandler';
import { useItandiUserSessions } from '~/hooks/api/useItandiUserSessions';
import { useAccounts } from '~/hooks/api/useAccount';
import { destroy as logoutStaff } from '~/utils/api/resources/itandiUserSession';
import Styles from './index.module.scss';

export const GlobalHeader: React.FC = () => {
  const { data: itandiSession } = useItandiAccountSessions().useShow();
  const { data: itandiUserSession } = useItandiUserSessions().useShow();
  const { data: accounts } = useAccounts().useIndex();
  const router = useRouter();
  const onClickItem = (path: string): void => {
    router.push(path).then();
  };
  const isActivePage = React.useCallback(
    (targetPath: string): boolean =>
      targetPath === '/'
        ? router.pathname === '/'
        : router.pathname.includes(targetPath),
    [router.pathname]
  );
  const handleLogout = React.useCallback(async () => {
    const response = await logout();
    if (isSuccess(response) && response.data?.url != null) {
      await router.replace(response.data.url);
    }
    // TODO: show error
  }, [router]);
  const handleLogoutStaff = React.useCallback(async () => {
    const response = await logoutStaff();
    if (isSuccess(response) && response.data?.url != null) {
      await router.replace(response.data.url);
    }
    // TODO: show error
  }, [router]);
  if (itandiSession === null || itandiUserSession === null) {
    return null;
  }
  const { companyName, storeName, productParams } = itandiSession;
  const { name } = itandiUserSession;

  const filteredAccounts = accounts?.map((account) => ({
    authId: account.authId,
    name: account.storeMasterName
  }));

  return (
    <>
      <ItandiHeader
        changeableStores={filteredAccounts}
        handleChangeStaff={handleLogoutStaff}
        handleLogout={handleLogout}
        isAdmin={itandiSession.adminLogin}
        itandiAccount={{ companyName, storeName }}
        productCode="yk"
        productParams={productParams}
        staffAccount={{ name }}
      >
        <ItandiHeaderItem
          active={isActivePage(CONTRACTS_PATH)}
          label="管理委託契約一覧"
          onClick={(): void => onClickItem(CONTRACTS_PATH)}
          url={CONTRACTS_PATH}
        />
        <ItandiHeaderItem
          active={isActivePage(OWNERS_PATH)}
          label="家主一覧"
          onClick={(): void => onClickItem(OWNERS_PATH)}
          url={OWNERS_PATH}
        />
        <ItandiHeaderItem
          active={isActivePage(SETTINGS_PATH)}
          label="設定"
          onClick={(): void => onClickItem(SETTINGS_PATH)}
          url={SETTINGS_PATH}
        />
      </ItandiHeader>
      {itandiSession.adminLogin && (
        <div className={Styles.AdminArea}>
          <div className={Styles.AdminAreaText}>【Adminログイン中】</div>
          <div className={Styles.AdminAreaText}>
            <ul>
              <li>閲覧のみ可能です</li>
              <li>ログアウトするとadminログインが終了します</li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
