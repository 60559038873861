import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/settings';

export const PATH = '/settings';

const Settings: NextPage = () => (
  <>
    <HeadsYkHead title="設定" />
    <Component />
  </>
);

export default Settings;
