import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
  width?: number;
  isVertical?: boolean;
}>;

export const TableData: React.FC<Props> = ({
  children,
  width = 15,
  isVertical = false
}: Props) => {
  const style = { width: `${width}%` };
  return (
    <td
      className={clsx(isVertical ? Styles.VerticalTableData : Styles.TableData)}
      style={style}
    >
      {children}
    </td>
  );
};
