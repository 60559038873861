import React from 'react';
import { TabMode } from '~/components/specifics/owners/[id]/components/Container';
import { OwnersContentsBasic } from '~/components/specifics/owners/[id]/components/Contents/Basic';
import { OwnerWithContracts } from '~/models/owner';

type Props = DeepReadonly<{
  tabMode: TabMode;
  owner: OwnerWithContracts;
}>;

export const OwnersContents: React.FC<Props> = ({ tabMode, owner }: Props) => (
  <div>{tabMode === 'basic' && <OwnersContentsBasic owner={owner} />}</div>
);
