import React, { ReactNode } from 'react';
import { InputSelect, InputRadio } from '@itandi/itandi-bb-ui';
import {
  OwnerCustomFieldSetting,
  OwnerCustomFieldSettingSelect
} from '~/models/ownerCustomFieldSetting';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  ownerCustomFieldSetting: OwnerCustomFieldSetting;
}>;

export const OwnersEditorBlockCustomFieldSelect: React.FC<Props> = ({
  ownerCustomFieldSetting
}: Props) => {
  const { register } = useFormContext<OwnerForEdit>();
  if (ownerCustomFieldSetting.fieldType !== 'select') {
    return null;
  }
  const setting: DeepReadonly<OwnerCustomFieldSettingSelect> =
    ownerCustomFieldSetting;
  if (setting.selectOptions.length <= 3) {
    return (
      <div className={Styles.RadioGroup}>
        {setting.selectOptions.map(
          (value: Readonly<{ value: string }>): ReactNode => (
            <InputRadio
              key={value.value}
              label={value.value}
              value={value.value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(
                `ownerCustomFieldValues.${ownerCustomFieldSetting.fieldKey}`
              )}
            />
          )
        )}
      </div>
    );
  }

  return (
    <InputSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(
        `ownerCustomFieldValues.${ownerCustomFieldSetting.fieldKey}`
      )}
    >
      <option value="">未選択</option>
      {setting.selectOptions.map(
        (value: Readonly<{ value: string }>): ReactNode => (
          <option key={value.value} value={value.value}>
            {value.value}
          </option>
        )
      )}
    </InputSelect>
  );
};
