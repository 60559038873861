import React from 'react';
import { OwnersSearchArea } from '~/components/specifics/owners/components/SearchArea';
import { OwnersList } from '~/components/specifics/owners/components/List';
import { Button } from '@itandi/itandi-bb-ui';
import { RiAddBoxLine } from 'react-icons/ri';
import { useRouter } from 'next/router';
import { PATH } from '~/pages/owners/new';
import { PATH as OWNERS_PATH } from '~/pages/owners';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import Styles from './index.module.scss';

export const Page: React.FC = () => {
  const router = useRouter();
  return (
    <LayoutsItandiSessionLayout>
      <LayoutsContainer
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem
              onClick={(): void => {
                router.push(OWNERS_PATH);
              }}
            >
              家主管理
            </BreadcrumbsItem>
            <BreadcrumbsItem isActive>家主一覧</BreadcrumbsItem>
          </Breadcrumbs>
        }
        title="家主一覧"
      >
        <div className={Styles.MiddleContainer}>
          <OwnersSearchArea />
          <span className={Styles.CreateButton}>
            <Button
              height="small"
              onClick={(): void => {
                router.push(PATH).then();
              }}
              startIcon={<RiAddBoxLine className={Styles.CreateIcon} />}
              variant="primary"
            >
              新規登録
            </Button>
          </span>
        </div>
        <OwnersList />
      </LayoutsContainer>
    </LayoutsItandiSessionLayout>
  );
};
