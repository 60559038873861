import React from 'react';
import clsx from 'clsx';
import Styles from './index.module.scss';

type VariantType = 'normal' | 'success' | 'danger' | 'warning';
type Props = DeepReadonly<{
  children: string;
  variant?: VariantType;
}>;

const variantStyles: { [field in VariantType]: string } = {
  normal: Styles.VariantNormal,
  success: Styles.VariantSuccess,
  danger: Styles.VariantDanger,
  warning: Styles.VariantWarning
};

export const StatusLabel: React.FC<Props> = ({
  children,
  variant = 'normal'
}: Props) => (
  <span className={clsx(Styles.StatusLabel, variantStyles[variant])}>
    {children}
  </span>
);
