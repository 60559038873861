import React from 'react';
import { RiMoneyCnyBoxLine } from 'react-icons/ri';
import { ContractWithOwners } from '~/models/contract';
import { ListCard } from '~/components/common/List/Card';
import { ListItem } from '~/components/common/List/Item';
import { TextWithUnitNumber } from '~/components/common/Text/WithUnit/Number/index';
import { TextInvoice } from '~/components/common/Text/Invoice/index';

type Props = DeepReadonly<{
  contract: ContractWithOwners;
}>;

export const ContractsRemittingContents: React.FC<Props> = ({
  contract
}: Props) => (
  <ListCard icon={<RiMoneyCnyBoxLine />} title="支払/請求">
    <ListItem
      content={
        <TextWithUnitNumber num={contract.subLeaseRent} unit="円(税抜)" />
      }
      title="サブリース賃料"
    />
    <ListItem
      content={
        <TextWithUnitNumber
          num={contract.subLeaseParkingRent}
          unit="円(税抜)"
        />
      }
      title="サブリース駐車場賃料"
    />
    <ListItem
      content={
        <TextInvoice
          feeType="new_contract_commission"
          invoices={contract.invoices}
        />
      }
      title="新規契約手数料"
    />
    <ListItem
      content={
        <TextInvoice
          feeType="renewal_commission"
          invoices={contract.invoices}
        />
      }
      title="更新時手数料"
    />
    <ListItem
      content={
        <TextInvoice
          feeType="cancellation_commission"
          invoices={contract.invoices}
        />
      }
      title="解約時手数料"
    />
  </ListCard>
);
