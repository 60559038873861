import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/owners/new';

export const PATH = '/owners/new';

const NewOwner: NextPage = () => (
  <>
    <HeadsYkHead title="家主の新規登録" />
    <Component />
  </>
);

export default NewOwner;
