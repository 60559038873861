import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { RiDraftLine } from 'react-icons/ri';
import { Button } from '@itandi/itandi-bb-ui';
import { PATH } from '~/pages/owners/[id]/edit';
import { OwnersContentsBasicIndividual } from '~/components/specifics/owners/[id]/components/Contents/Basic/Individual';
import { OwnersContentsBasicCorp } from '~/components/specifics/owners/[id]/components/Contents/Basic/Corp';
import { OwnersContentsBasicTel } from '~/components/specifics/owners/[id]/components/Contents/Basic/Tel';
import { OwnersContentsBasicEmail } from '~/components/specifics/owners/[id]/components/Contents/Basic/Email';
import { OwnersContentsBasicAddress } from '~/components/specifics/owners/[id]/components/Contents/Basic/Address';
import { displayDestName, OwnerWithContracts } from '~/models/owner';
import { OwnersContentsBasicCustomField } from '~/components/specifics/owners/[id]/components/Contents/Basic/OwnerCustomField';
import { displayDate } from '~/utils/dateUtils';
import { ListItem } from '~/components/common/List/Item';
import { ListCard } from '~/components/common/List/Card';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  owner: OwnerWithContracts;
}>;

export const getContentOrEmptyLabel = (content: string | null): ReactNode => {
  if (content === null || content === '') {
    return <span className={Styles.Empty}>未登録</span>;
  }
  return <span>{content}</span>;
};

export const OwnersContentsBasic: React.FC<Props> = ({ owner }: Props) => {
  const router = useRouter();
  const { id = null } = useRouter().query;

  return (
    <>
      <ListCard
        buttons={
          <Button
            height="small"
            onClick={(): void => {
              router.push(PATH(Number(id))).then();
            }}
            variant="secondary"
          >
            修正
          </Button>
        }
        icon={<RiDraftLine />}
        title="基本情報"
      >
        <ListItem content={owner.ownerKey} title="家主管理番号" />
        <ListItem
          content={owner.qualifiedInvoiceIssuerRegistrationNumber}
          title="適格請求書発行事業者 登録番号"
        />
        {owner.corp ? (
          <OwnersContentsBasicCorp />
        ) : (
          <OwnersContentsBasicIndividual />
        )}
        <OwnersContentsBasicTel />
        <OwnersContentsBasicEmail />
        <OwnersContentsBasicAddress />
        <ListItem
          content={owner.antisocialCheckDone ? '実施済' : '未実施'}
          title="反社チェック実施可否"
        />
        <ListItem
          content={getContentOrEmptyLabel(
            owner.antisocialCheckDate
              ? displayDate(owner.antisocialCheckDate)
              : null
          )}
          title="反社チェック実施日"
        />
        {owner.staff && (
          <ListItem
            content={getContentOrEmptyLabel(owner.staff.name)}
            title="担当者"
          />
        )}
        <ListItem
          content={getContentOrEmptyLabel(owner.destLastName)}
          title="送金用氏名 姓"
        />
        <ListItem
          content={getContentOrEmptyLabel(owner.destFirstName)}
          title="送金用氏名 名"
        />
        <ListItem
          content={getContentOrEmptyLabel(displayDestName(owner.destLabel))}
          title="送金用氏名の敬称"
        />
        <ListItem content={getContentOrEmptyLabel(owner.note)} title="備考" />
      </ListCard>
      <OwnersContentsBasicCustomField />
    </>
  );
};
