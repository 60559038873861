import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/contracts/Page';

export const PATH = '/contracts';

const Contracts: NextPage = () => (
  <>
    <HeadsYkHead title="管理委託契約一覧" />
    <Component />
  </>
);

export default Contracts;
