import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
  rowKey?: string | number;
  isInTableBody?: boolean;
  isBackGroundColorWhite?: boolean;
}>;

export const TableRow: React.FC<Props> = ({
  children,
  rowKey,
  isInTableBody = false
}: Props) => (
  <tr
    key={rowKey}
    className={clsx(Styles.TableRow, isInTableBody && Styles.InTableBody)}
  >
    {children}
  </tr>
);
