import React from 'react';
import { InputText } from '@itandi/itandi-bb-ui';
import { OwnerCustomFieldSetting } from '~/models/ownerCustomFieldSetting';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';

type Props = DeepReadonly<{
  ownerCustomFieldSetting: OwnerCustomFieldSetting;
}>;

export const OwnersEditorBlockCustomFieldText: React.FC<Props> = ({
  ownerCustomFieldSetting
}: Props) => {
  const { register } = useFormContext<OwnerForEdit>();
  return (
    <InputText
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(
        `ownerCustomFieldValues.${ownerCustomFieldSetting.fieldKey}`
      )}
    />
  );
};
