import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { RentBuilding } from '~/models/rentBuilding';

const paths = {
  index: '/kensakukun/rent_buildings'
};
type TIndex = (name: string) => SwrReturnType<ReadonlyArray<RentBuilding>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useRentBuildings = (): ReturnType => {
  const useIndex: TIndex = (name: string) => {
    const { data, error, isValidating } = useSWR<{
      rentBuildings: ReadonlyArray<RentBuilding>;
    }>(name ? `${paths.index}?name=${name}` : null);
    return {
      data: data ? data.rentBuildings : null,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
