import { useMemo } from 'react';
import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { OwnerWithContracts } from '~/models/owner';
import { useRouter } from 'next/router';
import { OwnerQuery } from '~/models/ownerQuery';
import { convertUrlQueryToOwnerQuery } from '~/utils/owners/ownerUrlQueryConverter';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

const paths = {
  index: '/owners',
  show: '/owners/:id'
};
type SearchParams = DeepReadonly<{
  name?: string;
  corpName?: string;
  page?: number;
}>;
type TIndex = (
  params?: SearchParams
) => SwrReturnType<Pageable<OwnerWithContracts>>;
type TShow = (id: number | null) => SwrReturnType<OwnerWithContracts>;
type ReturnType = Readonly<{
  useIndex: TIndex;
  useShow: TShow;
}>;

export const useOwners = (): ReturnType => {
  const useIndex: TIndex = (params?: SearchParams) => {
    const router = useRouter();
    const query: OwnerQuery | null = useMemo(() => {
      if (!router.isReady) {
        return null;
      }
      return convertUrlQueryToOwnerQuery(router.query);
    }, [router]);
    const searchParams = params || query;
    const { data, mutate, error } = useSWR<Pageable<OwnerWithContracts>>(
      searchParams === null
        ? null
        : buildUrlWithQuery(paths.index, searchParams)
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  const useShow: TShow = (id: number | null) => {
    const { data, mutate, error } = useSWR<OwnerWithContracts>(
      id === null ? null : paths.show.replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex, useShow };
};
