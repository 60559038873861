import { SwrReturnType } from '~/models/api';
import { OwnerCustomFieldSetting } from '~/models/ownerCustomFieldSetting';
import useSWR from 'swr';

const paths = {
  index: '/owner_custom_field_settings',
  show: '/owner_custom_field_settings/:id'
};
type TIndex = () => SwrReturnType<DeepReadonlyArray<OwnerCustomFieldSetting>>;
type TShow = (
  id: number
) => SwrReturnType<DeepReadonly<OwnerCustomFieldSetting>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
  useShow: TShow;
}>;

export const useOwnerCustomFieldSettings = (): ReturnType => {
  const useIndex: TIndex = () => {
    const { data, mutate, error } = useSWR<
      DeepReadonlyArray<OwnerCustomFieldSetting>
    >(paths.index);
    return {
      data: data ?? null,
      mutate: mutate ?? null,
      isLoading: !data && !error,
      error
    };
  };
  const useShow: TShow = (id: number) => {
    const { data, mutate, error } = useSWR<
      DeepReadonly<OwnerCustomFieldSetting>
    >(id === null ? null : paths.show.replace(':id', String(id)));
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex, useShow };
};
