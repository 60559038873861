import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/contracts/[id]';

export const PATH = (id: number): string => `/contracts/${id}`;

const Contract: NextPage = () => (
  <>
    <HeadsYkHead title="管理委託契約詳細" />
    <Component />
  </>
);

export default Contract;
