import React from 'react';
import { useRouter } from 'next/router';
import { OwnersTitleHeader } from '~/components/specifics/owners/[id]/components/TitleHeader';
import { OwnersContainer } from '~/components/specifics/owners/[id]/components/Container';
import { useOwners } from '~/hooks/api/useOwners';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';

export const Page: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  return (
    <LayoutsItandiSessionLayout>
      {owner !== null && (
        <div>
          <OwnersTitleHeader owner={owner} />
          <OwnersContainer />
        </div>
      )}
    </LayoutsItandiSessionLayout>
  );
};
