export const getItemsCountRange = (
  currentPage: number,
  count: number,
  itemsPerPage: number
): string => {
  if (count === 0) {
    return '0件';
  }

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex =
    currentPage * itemsPerPage > count ? count : currentPage * itemsPerPage;

  return `${startIndex}-${endIndex}件`;
};
