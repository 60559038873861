import React, { ReactNode } from 'react';
import { OwnerWithContracts } from '~/models/owner';
import { OwnersListTableBodyRow } from '~/components/specifics/owners/components/List/Table/Body/Row';
import { useOwners } from '~/hooks/api/useOwners';
import { TableBody } from '~/components/common/Table/Body';

export const OwnersListTableBody: React.FC = () => {
  const { data: owners } = useOwners().useIndex();
  const renderRow = React.useCallback(
    (owner: DeepReadonly<OwnerWithContracts>): ReactNode => (
      <OwnersListTableBodyRow key={owner.id} owner={owner} />
    ),
    []
  );
  if (owners === null) {
    return null;
  }
  return (
    <TableBody isBackGroundColorWhite>
      {owners.objects.map(renderRow)}
    </TableBody>
  );
};
