import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { ReportTemplate } from '~/models/reportTemplate';

const paths = {
  index: '/report_templates'
};
type TIndex = () => SwrReturnType<ReadonlyArray<ReportTemplate>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useReportTemplates = (): ReturnType => {
  const useIndex: TIndex = () => {
    const { data, mutate, error } = useSWR<ReadonlyArray<ReportTemplate>>(
      paths.index
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex };
};
