import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/owners/[id]';

export const PATH = (id: number): string => `/owners/${id}`;

const Owner: NextPage = () => (
  <>
    <HeadsYkHead title="家主詳細" />
    <Component />
  </>
);

export default Owner;
