import { useItandiAccountSessions } from './api/useItandiAccountSessions';

export const useUsingSeisanKanrikun = (): boolean => {
  const { data: useItandiAccountSession } =
    useItandiAccountSessions().useShow();

  if (useItandiAccountSession === null) {
    return false;
  }

  return useItandiAccountSession.usingSeisanKanrikun;
};
