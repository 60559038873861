import React, { ReactNode } from 'react';
import { ContractWithOwners } from '~/models/contract';
import { ContractsListTableBodyRow } from '~/components/specifics/contracts/components/List/Table/Body/Row';
import { useContracts } from '~/hooks/api/useContracts';
import { TableBody } from '~/components/common/Table/Body';

export const ContractsListTableBody: React.FC = () => {
  const { data: contracts } = useContracts().useIndex();
  const renderRow = React.useCallback(
    (contract: DeepReadonly<ContractWithOwners>): ReactNode => (
      <ContractsListTableBodyRow key={contract.id} contract={contract} />
    ),
    []
  );
  if (contracts === null) {
    return null;
  }
  return (
    <TableBody isBackGroundColorWhite>
      {contracts.objects.map(renderRow)}
    </TableBody>
  );
};
