import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { ContractHistory } from '~/models/contractHistory';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

const paths = {
  index: '/contracts/:id/contract_histories',
  show: '/contracts/:id/contract_histories/:history_id'
};
type TIndex = (id: number | null) => SwrReturnType<Pageable<ContractHistory>>;
type TShow = (
  id: number | null,
  historyId: number | null
) => SwrReturnType<ContractHistory>;
type ReturnType = Readonly<{
  useIndex: TIndex;
  useShow: TShow;
}>;

export const useContractHistories = (): ReturnType => {
  const useIndex: TIndex = (id: number | null) => {
    const router = useRouter();
    const query: { page?: number } | null = useMemo(() => {
      if (!router.isReady) {
        return null;
      }

      const { page } = router.query;
      return page ? { page: Number(page) } : {};
    }, [router]);
    const { data, mutate, error } = useSWR<Pageable<ContractHistory>>(
      id === null || query === null
        ? null
        : buildUrlWithQuery(paths.index.replace(':id', String(id)), query)
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  const useShow: TShow = (id: number | null, historyId: number | null) => {
    const { data, mutate, error } = useSWR<ContractHistory>(
      id === null
        ? null
        : paths.show
            .replace(':id', String(id))
            .replace(':history_id', String(historyId))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useIndex, useShow };
};
