import React from 'react';
import { useRouter } from 'next/router';
import { useContracts } from '~/hooks/api/useContracts';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { ContractHistoriesListTable } from '~/components/specifics/contracts/[id]/histories/components/ListTable';
import { PATH as CONTRACTS_PATH } from '~/pages/contracts';
import { PATH as CONTRACT_PATH } from '~/pages/contracts/[id]/index';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';

export const Page: React.FC = () => {
  const router = useRouter();
  const { id } = router.query;
  const { data: contract } = useContracts().useShow(id ? Number(id) : null);
  return (
    <LayoutsItandiSessionLayout>
      {contract !== null && (
        <LayoutsContainer
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbsItem
                onClick={(): void => {
                  router.push(CONTRACTS_PATH);
                }}
              >
                管理委託契約一覧
              </BreadcrumbsItem>
              <BreadcrumbsItem
                onClick={(): void => {
                  router.push(CONTRACT_PATH(contract.id));
                }}
              >
                管理委託契約詳細
              </BreadcrumbsItem>
              <BreadcrumbsItem isActive>管理委託契約履歴一覧</BreadcrumbsItem>
            </Breadcrumbs>
          }
          title="管理委託契約履歴一覧"
        >
          <ContractHistoriesListTable contract={contract} />
        </LayoutsContainer>
      )}
    </LayoutsItandiSessionLayout>
  );
};
