import React from 'react';
import { OwnersListTableHeader } from '~/components/specifics/owners/components/List/Table/Header';
import { OwnersListTableBody } from '~/components/specifics/owners/components/List/Table/Body';
import { TableContainer } from '~/components/common/Table/Container';

export const OwnersListTable: React.FC = () => (
  <TableContainer>
    <OwnersListTableHeader />
    <OwnersListTableBody />
  </TableContainer>
);
