import { YkResponse } from '~/models/api';
import { postRequest } from '~/utils/api/apiHandler';

const paths = {
  create: '/contracts/:contract_id/contract_approval'
};

export const create = async (
  contractId: number
): Promise<YkResponse<{ id: number }>> =>
  postRequest(paths.create.replace(':contract_id', String(contractId)));
