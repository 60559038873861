import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { RiUser3Line } from 'react-icons/ri';
import { Input, InputSelect, InputRadio } from '@itandi/itandi-bb-ui';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import { convertDateWithNull } from '~/utils/dateUtils';
import Styles from '../index.module.scss';

type Props = Readonly<{
  isFullNameForm: boolean;
  setIsFullNameForm: (value: boolean) => void;
}>;

export const OwnersEditorBlockIndividual: React.FC<Props> = ({
  isFullNameForm,
  setIsFullNameForm
}: Props) => {
  const { register, formState, setValue, watch } =
    useFormContext<OwnerForEdit>();
  const { errors } = formState;
  const formToggleMapping: { [field in string]: boolean } = {
    true: true,
    false: false
  };

  useEffect(() => {
    if (isFullNameForm) {
      const lastName = watch('lastName');
      const firstName = watch('firstName');
      const lastNameKana = watch('lastNameKana');
      const firstNameKana = watch('firstNameKana');

      setValue('fullName', `${lastName} ${firstName}`);
      setValue('fullNameKana', `${lastNameKana} ${firstNameKana}`);
    } else {
      const fullName = watch('fullName');
      const fullNameKana = watch('fullNameKana');

      const [lastName = '', firstName = ''] = fullName?.split(' ') ?? [];
      const [lastNameKana = '', firstNameKana = ''] =
        fullNameKana?.split(' ') ?? [];

      setValue('lastName', lastName);
      setValue('firstName', firstName);
      setValue('lastNameKana', lastNameKana);
      setValue('firstNameKana', firstNameKana);
    }
  }, [isFullNameForm, setValue, watch]);

  return (
    <FormBlock caption="家主情報" captionIcon={<RiUser3Line />}>
      <div className={Styles.InputRadioGroup}>
        <InputRadio
          checked={!isFullNameForm}
          label="氏名 分割入力"
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = e.target;
            setIsFullNameForm(formToggleMapping[value]);
          }}
          value="false"
        />
        <InputRadio
          checked={isFullNameForm}
          label="氏名 一括入力"
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = e.target;
            setIsFullNameForm(formToggleMapping[value]);
          }}
          value="true"
        />
      </div>

      <FormBlockRow label="氏名" required>
        <div className={Styles.NameInputGroup}>
          {isFullNameForm ? (
            <div className={Styles.NameRow}>
              <div className={Styles.NameInnerBlock}>
                <span className={Styles.NameLabel}>姓/名</span>
                <Input
                  isInvalid={errors.fullName != null}
                  placeholder="例）家主 太郎"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('fullName')}
                />
                {errors.fullName != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.fullName.message}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={Styles.NameRow}>
              <div className={Styles.NameInnerBlock}>
                <span className={Styles.NameLabel}>姓</span>
                <Input
                  isInvalid={errors.lastName != null}
                  placeholder="例）家主"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('lastName')}
                />
                {errors.lastName != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.lastName.message}
                  </div>
                )}
              </div>
              <div className={Styles.NameInnerBlock}>
                <span className={Styles.NameLabel}>名</span>
                <Input
                  isInvalid={errors.firstName != null}
                  placeholder="例）太郎"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('firstName')}
                />
                {errors.firstName != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.firstName.message}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </FormBlockRow>

      <FormBlockRow label="氏名カナ">
        <div className={Styles.NameInputGroup}>
          {isFullNameForm ? (
            <div className={Styles.NameRow}>
              <div className={Styles.NameInnerBlock}>
                <span className={Styles.NameLabel}>姓/名カナ</span>
                <Input
                  isInvalid={errors.fullNameKana != null}
                  placeholder="例）ヤヌシ タロウ"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('fullNameKana')}
                />
                {errors.fullNameKana != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.fullNameKana.message}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={Styles.NameRow}>
              <div className={Styles.NameInnerBlock}>
                <span className={Styles.NameLabel}>姓カナ</span>
                <Input
                  placeholder="例）ヤヌシ"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('lastNameKana')}
                />
                {errors.lastNameKana != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.lastNameKana.message}
                  </div>
                )}
              </div>
              <div className={Styles.NameInnerBlock}>
                <span className={Styles.NameLabel}>名カナ</span>
                <Input
                  placeholder="例）タロウ"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('firstNameKana')}
                />
                {errors.firstNameKana != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.firstNameKana.message}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.sex?.message} label="性別">
        <InputSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('sex')}
        >
          <option value="">選択なし</option>
          <option value="man">男性</option>
          <option value="woman">女性</option>
        </InputSelect>
      </FormBlockRow>

      <FormBlockRow label="生年月日">
        <InputDate
          onChange={(date: Readonly<Date> | null): void => {
            setValue('birthday', date === null ? '' : date.toString());
          }}
          selected={convertDateWithNull(watch('birthday'))}
        />
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.job?.message} label="職業">
        <Input
          placeholder="例）会社員"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('job')}
        />
      </FormBlockRow>
    </FormBlock>
  );
};
