import React from 'react';
import { useRouter } from 'next/router';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import { PATH as CONTRACTS_PATH } from '~/pages/contracts';
import { PATH as CONTRACT_PATH } from '~/pages/contracts/[id]/index';
import { PATH as CONTRACT_HISTORIES_PATH } from '~/pages/contracts/[id]/histories/index';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';
import { ContractHistoriesContent } from './components/Content';

export const Page: React.FC = () => {
  const router = useRouter();
  const { id } = router.query;
  return (
    <LayoutsItandiSessionLayout>
      <LayoutsContainer
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem
              onClick={(): void => {
                router.push(CONTRACTS_PATH);
              }}
            >
              管理委託契約一覧
            </BreadcrumbsItem>
            <BreadcrumbsItem
              onClick={(): void => {
                router.push(CONTRACT_PATH(Number(id)));
              }}
            >
              管理委託契約詳細
            </BreadcrumbsItem>
            <BreadcrumbsItem
              onClick={(): void => {
                router.push(CONTRACT_HISTORIES_PATH(Number(id)));
              }}
            >
              管理委託契約履歴一覧
            </BreadcrumbsItem>
            <BreadcrumbsItem isActive>管理委託契約履歴詳細</BreadcrumbsItem>
          </Breadcrumbs>
        }
      >
        <ContractHistoriesContent />
      </LayoutsContainer>
    </LayoutsItandiSessionLayout>
  );
};
