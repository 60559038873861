import React from 'react';
import { Pagination } from '@itandi/itandi-bb-ui';
import { useOwners } from '~/hooks/api/useOwners';
import { useRouter } from 'next/router';
import { convertUrlQueryToOwnerQuery } from '~/utils/owners/ownerUrlQueryConverter';
import { OwnerQuery } from '~/models/ownerQuery';
import { TableDisplayCount } from '~/components/common/Table/DisplayCount/index';
import { OwnersListTable } from './Table';
import Styles from './index.module.scss';

export const OwnersList: React.FC = () => {
  const router = useRouter();
  const { data: owners } = useOwners().useIndex();
  const handleChangePage = React.useCallback(
    (newPageNumber: number): void => {
      const query = convertUrlQueryToOwnerQuery(router.query);
      const newQuery: OwnerQuery = { ...query, page: newPageNumber };
      if (newPageNumber === 1) {
        delete newQuery.page;
      }
      router.push({ query: newQuery }).then();
    },
    [router]
  );
  if (owners === null) {
    return null;
  }
  return (
    <div>
      <OwnersListTable />
      <div className={Styles.TablePagination}>
        <TableDisplayCount
          count={owners.count}
          currentPage={owners.currentPage}
          itemsPerPage={30}
        />
        <Pagination
          currentPageNum={owners.currentPage}
          handleChangePage={handleChangePage}
          maxPageNum={owners.sumPageCount}
        />
      </div>
    </div>
  );
};
