import React from 'react';
import { ContractsSearchArea } from '~/components/specifics/contracts/components/SearchArea';
import { ContractsList } from '~/components/specifics/contracts/components/List';
import { useRouter } from 'next/router';
import { Button } from '@itandi/itandi-bb-ui';
import { PATH } from '~/pages/contracts/new';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { Drawer } from '~/components/common/Drawer';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { RiSearchLine } from 'react-icons/ri';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';
import Styles from './Page.module.scss';

export const Page: React.FC = () => {
  const router = useRouter();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();

  return (
    <LayoutsItandiSessionLayout>
      <LayoutsContainer title="管理委託契約一覧">
        <Button
          className={Styles.CreateButton}
          height="small"
          onClick={(): void => {
            router.push(PATH).then();
          }}
          variant="primary"
        >
          新規登録
        </Button>
        <button
          className={Styles.SearchButton}
          onClick={handleOpen}
          type="button"
        >
          <RiSearchLine />
          検索条件を指定する
        </button>
        <Drawer handleClose={handleClose} open={isOpen}>
          <ContractsSearchArea handleDrawerClose={handleClose} />
        </Drawer>
        <ContractsList />
      </LayoutsContainer>
    </LayoutsItandiSessionLayout>
  );
};
