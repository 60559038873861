import React from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  isActive?: boolean;
  href?: string | null;
  onClick?: (() => void) | null;
  children: string;
}>;

export const BreadcrumbsItem: React.FC<Props> = ({
  isActive = false,
  href = null,
  onClick = null,
  children
}: Props) => {
  const handleClick = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (e: React.MouseEvent): void => {
      if (href !== null || onClick === null) {
        return;
      }
      e.preventDefault();
      onClick();
    },
    [onClick, href]
  );
  return (
    <li>
      {isActive ? (
        <span className={Styles.BreadcrumbsCurrent}>{children}</span>
      ) : (
        <a
          className={Styles.BreadcrumbsLink}
          href={href ?? '#'}
          onClick={handleClick}
        >
          {children}
        </a>
      )}
    </li>
  );
};
