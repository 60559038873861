import React from 'react';
import { Input, Modal, Button } from '@itandi/itandi-bb-ui';
import { RentBuilding } from '~/models/rentBuilding';
import { RentRoom } from '~/models/rentRoom';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useRentBuildings } from '~/hooks/api/useRentBuildings';
import { useRentRooms } from '~/hooks/api/useRentRooms';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
}>;

export const ContractsEditorBuildingSelectorModal: React.FC<Props> = ({
  isOpen,
  handleClose
}: Props) => {
  // 建物検索

  // フォームの入力値
  const [inputName, setInputName] = React.useState<string>('');
  // APIリクエスト用の建物名
  const [searchName, setSearchName] = React.useState<string>('');
  const {
    data: rentBuildings,
    isLoading: rentBuildingsLoading,
    error: rentBuildingsError
  } = useRentBuildings().useIndex(searchName);
  const [searchedRentBuildings, setSearchedRentBuildings] =
    React.useState<boolean>(false);

  // 選択した建物
  const [selectedRentBuilding, setSelectedRentBuilding] =
    React.useState<RentBuilding | null>(null);

  // 物件検索
  const [buildingPropertyId, setBuildingPropertyId] = React.useState<
    number | null
  >(null);
  const {
    data: rentRooms,
    isLoading: rentRoomsLoading,
    error: rentRoomsError
  } = useRentRooms().useIndex(buildingPropertyId);

  const { setValue } = useFormContext<DeepReadonly<ContractForEdit>>();

  const handleNameChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputName(e.target.value);
  };

  const handleSearchSubmit = async (): Promise<void> => {
    setSearchedRentBuildings(true);
    setSearchName(inputName);
  };

  const handleRentBuildingSelected = async (
    rentBuilding: RentBuilding
  ): Promise<void> => {
    setSelectedRentBuilding(rentBuilding);
    setBuildingPropertyId(rentBuilding.propertyId);
  };

  const handlePropertySelected = (property: RentBuilding | RentRoom): void => {
    setValue('property', {
      kensakukunPropertyId: property.propertyId,
      name: property.name,
      roomNumber: 'number' in property ? property.number : null,
      parkingSpaceNumber: null,
      propertyType: property.propertyType
    });
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={selectedRentBuilding ? '部屋を選択' : '建物を選択'}
      useDefaultFooter={false}
    >
      {!selectedRentBuilding ? (
        <>
          <div>
            <Input onChange={handleNameChange} value={inputName} />
            <div className={Styles.ButtonWrapper}>
              <Button
                disabled={!inputName}
                height="small"
                onClick={handleSearchSubmit}
                variant="primary"
              >
                検索する
              </Button>
            </div>
          </div>
          {!rentBuildingsLoading && rentBuildings && (
            <ul className={Styles.List}>
              {rentBuildings.map((rentBuilding: RentBuilding) => (
                <li key={rentBuilding.propertyId}>
                  <button
                    className={Styles.Link}
                    onClick={(): Promise<void> =>
                      handleRentBuildingSelected(rentBuilding)
                    }
                    type="button"
                  >
                    <div className={Styles.Key}>
                      {rentBuilding.name} {rentBuilding.address}
                    </div>
                    <RiArrowRightSLine className={Styles.ArrowLink} />
                  </button>
                </li>
              ))}
            </ul>
          )}
          {searchedRentBuildings &&
            !rentBuildingsLoading &&
            rentBuildings?.length === 0 && (
              <div className={Styles.FetchResultText}>検索結果が0件です</div>
            )}
          {rentBuildingsLoading && (
            <div className={Styles.FetchResultText}>ロード中</div>
          )}
          {rentBuildingsError && (
            <div className={Styles.FetchResultText}>
              データ取得中にエラーが発生しました
            </div>
          )}
        </>
      ) : (
        <ul className={Styles.List}>
          <button
            className={Styles.BackSelectBuildingLink}
            onClick={(): void => setSelectedRentBuilding(null)}
            type="button"
          >
            建物選択に戻る
          </button>
          {!rentRoomsLoading && rentRooms && (
            <>
              <li key="一棟">
                <button
                  className={Styles.Link}
                  onClick={(): void =>
                    handlePropertySelected(selectedRentBuilding)
                  }
                  type="button"
                >
                  <div className={Styles.Key}>一棟ごと選択</div>
                  <RiArrowRightSLine className={Styles.ArrowLink} />
                </button>
              </li>
              {rentRooms.map((rentRoom: RentRoom) => (
                <li key={rentRoom.propertyId}>
                  <button
                    className={Styles.Link}
                    onClick={(): void => handlePropertySelected(rentRoom)}
                    type="button"
                  >
                    <div className={Styles.Key}>{rentRoom.number}</div>
                    <RiArrowRightSLine className={Styles.ArrowLink} />
                  </button>
                </li>
              ))}
            </>
          )}
          {rentRoomsLoading && (
            <div className={Styles.FetchResultText}>ロード中</div>
          )}
          {rentRoomsError && (
            <div className={Styles.FetchResultText}>
              データ取得中にエラーが発生しました
            </div>
          )}
        </ul>
      )}
    </Modal>
  );
};
