import React from 'react';
import { OwnerCustomFieldSetting } from '~/models/ownerCustomFieldSetting';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import { convertDateWithNull } from '~/utils/dateUtils';

type Props = DeepReadonly<{
  ownerCustomFieldSetting: OwnerCustomFieldSetting;
}>;

export const OwnersEditorBlockCustomFieldDate: React.FC<Props> = ({
  ownerCustomFieldSetting
}: Props) => {
  const key: `ownerCustomFieldValues.${string}` = `ownerCustomFieldValues.${ownerCustomFieldSetting.fieldKey}`;
  const { setValue, watch } = useFormContext<OwnerForEdit>();
  const onChangeDate = React.useCallback(
    (date: Readonly<Date> | null) => {
      if (date === null) {
        setValue(key, '');
        return;
      }
      setValue(key, date.toString());
    },
    [key, setValue]
  );
  return (
    <InputDate
      onChange={(date: Readonly<Date> | null): void => onChangeDate(date)}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      selected={convertDateWithNull(watch(key) as string | null)}
    />
  );
};
