import React, { ReactNode } from 'react';
import {
  OwnerCustomFieldSetting,
  OwnerCustomFieldType
} from '~/models/ownerCustomFieldSetting';
import { RiMoreLine } from 'react-icons/ri';
import { OwnersEditorBlockCustomFieldString } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField/String';
import { OwnersEditorBlockCustomFieldText } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField/Text';
import { OwnersEditorBlockCustomFieldDate } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField/Date';
import { OwnersEditorBlockCustomFieldSelect } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField/Select';
import { OwnersEditorBlockCustomFieldNumber } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField/Number';
import { OwnersEditorBlockCustomFieldBoolean } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField/Boolean';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';

type Props = DeepReadonly<{
  ownerCustomFieldSettings: OwnerCustomFieldSetting[];
}>;
type RowProps = DeepReadonly<{
  ownerCustomFieldSetting: OwnerCustomFieldSetting;
}>;

const InputComponents: {
  [field in OwnerCustomFieldType]: React.FC<{
    ownerCustomFieldSetting: DeepReadonly<OwnerCustomFieldSetting>;
  }>;
} = {
  string: OwnersEditorBlockCustomFieldString,
  text: OwnersEditorBlockCustomFieldText,
  number: OwnersEditorBlockCustomFieldNumber,
  date: OwnersEditorBlockCustomFieldDate,
  select: OwnersEditorBlockCustomFieldSelect,
  boolean: OwnersEditorBlockCustomFieldBoolean
};

const OwnerCustomFieldBlockRow: React.FC<RowProps> = ({
  ownerCustomFieldSetting
}: RowProps) => {
  const InputComponent = InputComponents[ownerCustomFieldSetting.fieldType];

  return (
    <FormBlockRow label={ownerCustomFieldSetting.name}>
      <InputComponent ownerCustomFieldSetting={ownerCustomFieldSetting} />
    </FormBlockRow>
  );
};

export const OwnersEditorBlockCustomField: React.FC<Props> = ({
  ownerCustomFieldSettings
}: Props) => {
  if (ownerCustomFieldSettings.length === 0) return null;
  return (
    <FormBlock caption="補足情報" captionIcon={<RiMoreLine />}>
      {ownerCustomFieldSettings.map(
        (setting: DeepReadonly<OwnerCustomFieldSetting>): ReactNode => (
          <OwnerCustomFieldBlockRow
            key={setting.id}
            ownerCustomFieldSetting={setting}
          />
        )
      )}
    </FormBlock>
  );
};
