import React from 'react';
import { CustomFieldSetting } from '~/models/customFieldSetting';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import { InputDate } from '~/components/common/Form/Datepicker/InputDate';
import { convertDateWithNull } from '~/utils/dateUtils';

type Props = DeepReadonly<{
  customFieldSetting: CustomFieldSetting;
}>;

export const ContractsEditorBlockCustomFieldDate: React.FC<Props> = ({
  customFieldSetting
}: Props) => {
  const key: `customFieldValues.${string}` = `customFieldValues.${customFieldSetting.fieldKey}`;
  const { setValue, watch } = useFormContext<ContractForEdit>();
  const onChangeDate = React.useCallback(
    (date: Readonly<Date> | null) => {
      if (date === null) {
        setValue(key, '');
        return;
      }
      setValue(key, date.toString());
    },
    [key, setValue]
  );
  return (
    <InputDate
      onChange={(date: Readonly<Date> | null): void => onChangeDate(date)}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      selected={convertDateWithNull(watch(key) as string | null)}
    />
  );
};
