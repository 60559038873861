import React from 'react';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import { PATH } from '~/pages/contracts';
import { ContractsEditor } from '~/components/common/Contracts/Editor';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';

export const Page: React.FC = () => (
  <LayoutsItandiSessionLayout>
    <LayoutsContainer
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbsItem href={PATH}>管理委託契約一覧</BreadcrumbsItem>
          <BreadcrumbsItem isActive>契約の新規登録</BreadcrumbsItem>
        </Breadcrumbs>
      }
      fullBodyWidth
      title="管理委託契約の新規登録"
    >
      <ContractsEditor editorMode="new" />
    </LayoutsContainer>
  </LayoutsItandiSessionLayout>
);
