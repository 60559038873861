import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
  title?: string;
  breadcrumbs?: ReactNode;
  fullBodyWidth?: boolean;
}>;

export const LayoutsContainer: React.FC<Props> = ({
  children,
  title,
  breadcrumbs,
  fullBodyWidth = false
}: Props) => (
  <>
    <div className={Styles.Container}>
      {breadcrumbs && <nav className={Styles.Breadcrumbs}>{breadcrumbs}</nav>}
      {title && <h1 className={Styles.Title}>{title}</h1>}
    </div>
    <div className={!fullBodyWidth ? Styles.BodyContainer : undefined}>
      {children}
    </div>
  </>
);
