import queryString, { StringifiableRecord } from 'query-string';
import { decamelizeKeys } from 'humps';

type QueryParams = Record<
  string,
  string | number | boolean | ReadonlyArray<number> | undefined
>;

const decamelizeKeyCondition = (
  query: DeepReadonly<QueryParams>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): StringifiableRecord => decamelizeKeys(query) as QueryParams;

export const buildUrlWithQuery = (
  basePath: string,
  params: DeepReadonly<QueryParams>
): string =>
  queryString.stringifyUrl(
    { url: basePath, query: decamelizeKeyCondition(params) },
    { skipNull: true, skipEmptyString: true }
  );
