import { YkResponse } from '~/models/api';
import { getRequest, postRequest, deleteRequest } from '~/utils/api/apiHandler';
import { ReportTemplateParams } from '~/models/reportTemplate';

const paths = {
  show: '/report_templates/:id',
  create: '/report_templates',
  destroy: '/report_templates/:id'
};

export const show = async (
  reportTemplateId: number
): Promise<YkResponse<{ endpoint: string }>> =>
  getRequest(paths.show.replace(':id', String(reportTemplateId)));

export const create = async (
  reportTemplateParams: ReportTemplateParams
): Promise<YkResponse<void>> => {
  const formData: FormData = new FormData();
  formData.append('reportTemplate[title]', reportTemplateParams.title);
  if (reportTemplateParams.file) {
    formData.append('reportTemplate[file]', reportTemplateParams.file);
  }

  return postRequest(paths.create, formData);
};

export const destroy = async (
  reportTemplateId: number
): Promise<YkResponse<void>> =>
  deleteRequest(paths.destroy.replace(':id', String(reportTemplateId)));
