import React from 'react';
import { Setting, SettingsListMenuItem } from '../MenuItem';
import Styles from './index.module.scss';

type Props = Readonly<{
  settings: ReadonlyArray<Setting>;
}>;

export const SettingsListMenuList: React.FC<Props> = ({ settings }: Props) => (
  <div className={Styles.Container}>
    {settings.map((setting: Setting) => (
      <SettingsListMenuItem setting={setting} />
    ))}
  </div>
);
