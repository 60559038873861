import React from 'react';
import {
  Invoice,
  InvoiceFeeType,
  invoiceUnitTypeLabels
} from '~/models/invoice';
import { TextWithUnitNumber } from '../WithUnit/Number';

type Props = DeepReadonly<{
  invoices: DeepReadonlyArray<Invoice>;
  feeType: InvoiceFeeType;
}>;

export const TextInvoice: React.FC<Props> = ({ invoices, feeType }: Props) => {
  const invoice =
    invoices.find(
      (inv: DeepReadonlyObject<Invoice>) => inv.feeType === feeType
    ) || null;

  return invoice ? (
    <TextWithUnitNumber
      num={invoice.amount ? Number(invoice.amount) : null}
      unit={invoiceUnitTypeLabels[invoice.unitType]}
    />
  ) : (
    <TextWithUnitNumber num={null} />
  );
};
