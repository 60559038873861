import React from 'react';
import { KeyedMutator } from 'swr';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import {
  Contract,
  ContractForEdit,
  defaultContractValue
} from '~/models/contract';
import { convertFormInvoices } from '~/models/invoice';
import { ContractAuthIdEditorBlock } from './Block';

type Props = DeepReadonly<{
  handleClose: () => void;
  contract: Contract;
  contractMutate?: KeyedMutator<Contract>;
  updateActionPath: string;
  authList: { authId: number; name: string }[];
}>;

export const ContractAuthIdEditor: React.FC<Props> = ({
  handleClose,
  contract,
  contractMutate,
  updateActionPath,
  authList
}: Props) => {
  const methods: UseFormReturn<DeepReadonly<ContractForEdit>> = useForm<
    DeepReadonly<ContractForEdit>
  >({
    mode: 'onBlur',
    defaultValues: contract
      ? {
          ...contract,
          property: contract.property
            ? {
                kensakukunPropertyId: contract.property.kensakukunPropertyId,
                name: contract.property.name,
                roomNumber: contract.property.roomNumber,
                parkingSpaceNumber: contract.property.parkingSpaceNumber,
                propertyType: contract.property.propertyType
              }
            : defaultContractValue().property,
          invoices: convertFormInvoices(contract.invoices)
        }
      : defaultContractValue()
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <ContractAuthIdEditorBlock
        authList={authList}
        contractMutate={contractMutate}
        handleClose={handleClose}
        updateActionPath={updateActionPath}
      />
    </FormProvider>
  );
};
