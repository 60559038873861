import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { Account } from '~/models/account';

const paths = {
  index: '/accounts'
};
type TIndex = () => SwrReturnType<DeepReadonlyArray<Account>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useAccounts = (): ReturnType => {
  const useIndex: TIndex = () => {
    const { data, mutate, error, isValidating } = useSWR<
      DeepReadonlyArray<Account>
    >(paths.index);
    return {
      data: data ?? null,
      mutate,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
