import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { RiBuilding4Line } from 'react-icons/ri';
import { Input, InputText } from '@itandi/itandi-bb-ui';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from '../index.module.scss';

export const OwnersEditorBlockCorp: React.FC = () => {
  const { register, formState } = useFormContext<OwnerForEdit>();
  const { errors } = formState;
  return (
    <FormBlock caption="連絡先情報" captionIcon={<RiBuilding4Line />}>
      <FormBlockRow label="会社名" required>
        <Input
          isInvalid={errors.corpName != null}
          placeholder="例）サンプル株式会社"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('corpName')}
        />
        {errors.corpName != null && (
          <div className={Styles.ErrorMessage}>{errors.corpName.message}</div>
        )}
        <span className={Styles.KanaLabel}>カナ</span>
        <Input
          isInvalid={errors.corpNameKana != null}
          placeholder="例）サンプルカブシキガイシャ"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('corpNameKana')}
        />
        {errors.corpNameKana != null && (
          <div className={Styles.ErrorMessage}>
            {errors.corpNameKana.message}
          </div>
        )}
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.ceoName?.message} label="法人代表者名">
        <Input
          isInvalid={errors.ceoName != null}
          placeholder="例）代表 太郎"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('ceoName')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.ceoTitle?.message}
        label="法人代表者役職"
      >
        <Input
          isInvalid={errors.ceoTitle != null}
          placeholder="例）代表取締役"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('ceoTitle')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.corpContactName?.message}
        label="法人担当者名"
      >
        <Input
          isInvalid={errors.corpContactName != null}
          placeholder="例）担当 太郎"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('corpContactName')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.corpContactNote?.message}
        label="法人担当者備考"
      >
        <InputText
          isInvalid={errors.corpContactNote != null}
          placeholder="例）人事部"
          rows={3}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('corpContactNote')}
        />
      </FormBlockRow>
    </FormBlock>
  );
};
