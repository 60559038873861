import React from 'react';
import Head from 'next/head';

type Props = Readonly<{
  title: string;
}>;

export const HeadsYkHead: React.FC<Props> = ({ title }: Props) => (
  <Head>
    <title>{`${title} | 家主管理くん`}</title>
    <link href="/favicon.ico" rel="icon" />
    <meta
      content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
      name="viewport"
    />
  </Head>
);
