import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/contracts/[id]/renewal';

export const PATH = (id: number): string => `/contracts/${id}/renewal`;

const RenewalContract: NextPage = () => (
  <>
    <HeadsYkHead title="管理委託契約の更新" />
    <Component />
  </>
);

export default RenewalContract;
