import React from 'react';
import { KeyedMutator } from 'swr';
import { useFormContext } from 'react-hook-form';
import { Button, InputSelect } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { isSuccess } from '~/utils/api/apiHandler';
import { updateAuthId } from '~/utils/api/resources/contract';
import { Contract, ContractForEdit } from '~/models/contract';
import { FormBlock } from '~/components/common/Form/Block';
import { FormBlockRow } from '~/components/common/Form/Block/Row';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  handleClose: () => void;
  contractMutate?: KeyedMutator<Contract>;
  updateActionPath: string;
  authList: { authId: number; name: string }[];
}>;

export const ContractAuthIdEditorBlock: React.FC<Props> = ({
  handleClose,
  contractMutate,
  authList
}: Props) => {
  const { watch, handleSubmit, register } = useFormContext<ContractForEdit>();

  const router = useRouter();
  const { id } = router.query;
  const submitUpdate = async (): Promise<void> => {
    const response = await updateAuthId(Number(id), String(watch('authId')));
    if (isSuccess(response)) {
      contractMutate?.();
      handleClose();
      await router.push('/contracts');
      toast.success('支店を変更しました');
      return;
    }
    toast.error(response.message);
  };

  return (
    <>
      <FormBlock>
        <FormBlockRow label="支店変更">
          <InputSelect
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('authId')}
          >
            {authList.map(({ authId, name }) => (
              <option key={authId} value={authId}>
                {name}
              </option>
            ))}
          </InputSelect>
        </FormBlockRow>
      </FormBlock>
      <Button
        className={Styles.SubmitButton}
        onClick={handleSubmit(submitUpdate)}
        variant="primary"
        width="fill"
      >
        変更
      </Button>
    </>
  );
};
