import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import { Input, InputSelect } from '@itandi/itandi-bb-ui';
import { RiMoneyCnyBoxLine } from 'react-icons/ri';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from '../index.module.scss';

export const ContractsEditorBlockPaymentAndInvoiceBlock: React.FC = () => {
  const { register, formState } = useFormContext<ContractForEdit>();
  const { errors } = formState;

  return (
    <FormBlock caption="支払/請求" captionIcon={<RiMoneyCnyBoxLine />}>
      <FormBlockRow
        errorMessage={errors.subLeaseRent?.message}
        label="サブリース賃料(税抜)"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.subLeaseRent != null}
              placeholder="100000"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`subLeaseRent`)}
            />
          </span>
          <span className={Styles.Unit}>円</span>
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.subLeaseParkingRent?.message}
        label="サブリース駐車場賃料(税抜)"
      >
        <div className={Styles.InputWithUnitWrapper}>
          <span className={Styles.NumberInput}>
            <Input
              isInvalid={errors.subLeaseParkingRent != null}
              placeholder="100000"
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`subLeaseParkingRent`)}
            />
          </span>
          <span className={Styles.Unit}>円</span>
        </div>
      </FormBlockRow>

      <FormBlockRow label="新規契約手数料">
        <div className={Styles.FlexFormContent}>
          <div className={Styles.ColumnLayout}>
            <div className={Styles.AboveFormLabel}>
              <span className={Styles.FormLabelText}>金額</span>
            </div>
            <div className={Styles.InputWithUnitWrapper}>
              <span className={Styles.NumberInput}>
                <Input
                  isInvalid={errors.invoices?.new_contract_commission != null}
                  placeholder="10000"
                  type="number"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register(`invoices.new_contract_commission.amount`)}
                />
              </span>
            </div>
            {errors.invoices?.new_contract_commission != null && (
              <div className={Styles.ErrorMessage}>
                {errors.invoices.new_contract_commission.message}
              </div>
            )}
          </div>
          <div className={Styles.ColumnLayout}>
            <div className={Styles.AboveFormLabel}>
              <span className={Styles.FormLabelText}>単位</span>
            </div>
            <InputSelect
              isInvalid={errors.invoices?.new_contract_commission != null}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`invoices.new_contract_commission.unitType`)}
            >
              <option value="">選択なし</option>
              <option value="yen_without_tax">円(税抜)</option>
              <option value="month">ヶ月</option>
            </InputSelect>
            {errors.invoices?.new_contract_commission != null && (
              <div className={Styles.ErrorMessage}>
                {errors.invoices.new_contract_commission.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>

      <FormBlockRow label="更新時手数料">
        <div className={Styles.FlexFormContent}>
          <div className={Styles.ColumnLayout}>
            <div className={Styles.AboveFormLabel}>
              <span className={Styles.FormLabelText}>金額</span>
            </div>
            <div className={Styles.InputWithUnitWrapper}>
              <span className={Styles.NumberInput}>
                <Input
                  isInvalid={errors.invoices?.renewal_commission != null}
                  placeholder="100000"
                  type="number"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register(`invoices.renewal_commission.amount`)}
                />
              </span>
            </div>
            {errors.invoices?.renewal_commission != null && (
              <div className={Styles.ErrorMessage}>
                {errors.invoices.renewal_commission.message}
              </div>
            )}
          </div>
          <div className={Styles.ColumnLayout}>
            <div className={Styles.AboveFormLabel}>
              <span className={Styles.FormLabelText}>単位</span>
            </div>
            <InputSelect
              isInvalid={errors.invoices?.renewal_commission != null}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`invoices.renewal_commission.unitType`)}
            >
              <option value="">選択なし</option>
              <option value="yen_without_tax">円(税抜)</option>
              <option value="month">ヶ月</option>
            </InputSelect>
            {errors.invoices?.renewal_commission != null && (
              <div className={Styles.ErrorMessage}>
                {errors.invoices.renewal_commission.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>

      <FormBlockRow label="解約時手数料">
        <div className={Styles.FlexFormContent}>
          <div className={Styles.ColumnLayout}>
            <div className={Styles.AboveFormLabel}>
              <span className={Styles.FormLabelText}>金額</span>
            </div>
            <div className={Styles.InputWithUnitWrapper}>
              <span className={Styles.NumberInput}>
                <Input
                  isInvalid={errors.invoices?.cancellation_commission != null}
                  placeholder="100000"
                  type="number"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register(`invoices.cancellation_commission.amount`)}
                />
              </span>
            </div>
            {errors.invoices?.cancellation_commission != null && (
              <div className={Styles.ErrorMessage}>
                {errors.invoices.cancellation_commission.message}
              </div>
            )}
          </div>
          <div className={Styles.ColumnLayout}>
            <div className={Styles.AboveFormLabel}>
              <span className={Styles.FormLabelText}>単位</span>
            </div>
            <InputSelect
              isInvalid={errors.invoices?.cancellation_commission != null}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`invoices.cancellation_commission.unitType`)}
            >
              <option value="">選択なし</option>
              <option value="yen_without_tax">円(税抜)</option>
              <option value="month">ヶ月</option>
            </InputSelect>
            {errors.invoices?.cancellation_commission != null && (
              <div className={Styles.ErrorMessage}>
                {errors.invoices.cancellation_commission.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>
    </FormBlock>
  );
};
