import React, { useState, ReactNode } from 'react';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import { useRouter } from 'next/router';
import { PATH as CONTRACTS_PATH } from '~/pages/contracts';
import { PATH as OWNER_PATH } from '~/pages/owners/[id]';
import { ContractWithOwners } from '~/models/contract';
import { RiUserLine } from 'react-icons/ri';
import Link from 'next/link';
import { StatusLabel } from '~/components/common/StatusLabel';
import { Button, Alert } from '@itandi/itandi-bb-ui';
import { create } from '~/utils/api/resources/contracts/contractApproval';
import { toast } from 'react-toastify';
import { isSuccess } from '~/utils/api/apiHandler';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  contract: ContractWithOwners;
}>;

export const ContractsTitleHeader: React.FC<Props> = ({ contract }: Props) => {
  const router = useRouter();
  const { id } = router.query;
  const [approved, setApproved] = useState<boolean>(
    contract.contractApproval !== null
  );
  const onClickApproval = async (): Promise<void> => {
    // eslint-disable-next-line no-alert
    if (window.confirm('本当に承認しても良いですか？')) {
      const response = await create(Number(id));
      if (isSuccess(response)) {
        setApproved(true);
        toast.success('承認しました');
        return;
      }
      toast.error('承認に失敗しました');
    }
  };
  const renderStatusLabel = (): ReactNode => {
    if (contract.archived) {
      return <StatusLabel variant="danger">解約済み</StatusLabel>;
    }
    if (approved) {
      return <StatusLabel variant="success">契約中</StatusLabel>;
    }
    return <StatusLabel>契約準備中</StatusLabel>;
  };

  return (
    <div className={Styles.TitleHeader}>
      <nav className={Styles.BreadcrumbsWrapper}>
        <Breadcrumbs>
          <BreadcrumbsItem
            onClick={(): void => {
              router.push(CONTRACTS_PATH).then();
            }}
          >
            管理委託契約一覧
          </BreadcrumbsItem>
          <BreadcrumbsItem isActive>契約詳細</BreadcrumbsItem>
        </Breadcrumbs>
      </nav>
      <div className={Styles.TitleContents}>
        {renderStatusLabel()}
        <h1 className={Styles.Title}>
          {contract.property ? contract.property.displayName : '管理対象未登録'}
        </h1>
        {contract.owners.length > 0 && (
          <div className={Styles.Owners}>
            <RiUserLine className={Styles.OwnerIcon} />
            <Link href={OWNER_PATH(contract.owners[0].id)}>
              <a className={Styles.OwnerName}>
                {contract.owners[0].lastName} {contract.owners[0].firstName}
              </a>
            </Link>
          </div>
        )}
      </div>
      {contract.archived ? (
        <Alert variant="warning">
          この管理委託契約は解約済みです。「解約の取り消し」から取り消すことができます。
        </Alert>
      ) : (
        !approved && (
          <div className={Styles.ApprovalArea}>
            <div className={Styles.ApprovalAreaText}>
              この管理委託契約は契約準備中です。承認すると契約中になります。
            </div>
            <div className={Styles.ApprovalAreaButtonWrapper}>
              <Button height="small" onClick={onClickApproval} variant="danger">
                承認
              </Button>
            </div>
          </div>
        )
      )}
    </div>
  );
};
