import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const TableHead: React.FC<Props> = ({ children }: Props) => (
  <thead className={Styles.TableHead}>{children}</thead>
);
