import React, { ReactNode } from 'react';
import { Input, Modal, Button, Toggle } from '@itandi/itandi-bb-ui';
import { Owner } from '~/models/owner';
import { ContractsEditorOwnerSelectorModalRow } from '~/components/specifics/contracts/components/Editor/OwnerSelector/Modal/Row';
import { useOwners } from '~/hooks/api/useOwners';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
}>;

export const ContractsEditorOwnerSelectorModal: React.FC<Props> = ({
  isOpen,
  handleClose
}: Props) => {
  const [inputName, setInputName] = React.useState<string>('');
  const [searchName, setSearchName] = React.useState<string>('');
  const [isCorp, setIsCorp] = React.useState<boolean>(false);
  const handleNameChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputName(e.target.value);
  };
  const { getValues } = useFormContext<DeepReadonly<ContractForEdit>>();
  // 法人と個人の場合で、クエリが異なる(name/corpName)ので、isCorpをみてkeyを変える
  const { data: owners } = useOwners().useIndex(
    isCorp ? { corpName: searchName } : { name: searchName }
  );
  const renderRow = React.useCallback(
    (owner: DeepReadonly<Owner>): ReactNode => {
      const selectedOwners = getValues('owners');
      if (selectedOwners.some((o: DeepReadonly<Owner>) => o.id === owner.id)) {
        return <React.Fragment key={owner.id} />;
      }
      return (
        <ContractsEditorOwnerSelectorModalRow
          key={owner.id}
          handleClose={handleClose}
          owner={owner}
        />
      );
    },
    [handleClose, getValues]
  );
  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title="家主を選択"
      useDefaultFooter={false}
    >
      <div>
        <div className={Styles.ToggleWrapper}>
          {isCorp ? '家主名での検索に戻す' : '会社名での検索に切り替える'}
          <Toggle
            checked={isCorp}
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setIsCorp(e.target.checked)
            }
          />
        </div>
        <Input onChange={handleNameChange} value={inputName} />
        <div className={Styles.ButtonWrapper}>
          <Button
            height="small"
            onClick={(): void => setSearchName(inputName)}
            variant="primary"
          >
            検索する
          </Button>
        </div>
      </div>
      {owners !== null && (
        <ul className={Styles.OwnerList}>{owners.objects.map(renderRow)}</ul>
      )}
    </Modal>
  );
};
