import React from 'react';
import { getItemsCountRange } from '~/utils/pagination';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  count: number;
  currentPage: number;
  itemsPerPage: number;
}>;

export const TableDisplayCount: React.FC<Props> = ({
  count,
  currentPage,
  itemsPerPage
}: Props) => {
  const itemsCountRange = getItemsCountRange(currentPage, count, itemsPerPage);

  return (
    <p className={Styles.Container}>
      <strong>{count}</strong>件中<strong> {itemsCountRange}</strong>件
    </p>
  );
};
