import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/contracts/[id]/histories/[historyId]';

export const PATH = (contractId: number, id: number): string =>
  `/contracts/${contractId}/histories/${id}`;

const ContractHistory: NextPage = () => (
  <>
    <HeadsYkHead title="管理委託契約履歴詳細" />
    <Component />
  </>
);

export default ContractHistory;
