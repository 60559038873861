import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
  isBackGroundColorWhite?: boolean;
}>;

export const TableBody: React.FC<Props> = ({
  children,
  isBackGroundColorWhite = false
}: Props) => (
  <tbody className={isBackGroundColorWhite ? Styles.BackGroundColorWhite : ''}>
    {children}
  </tbody>
);
