import React from 'react';
import { ContractFileParams } from '~/models/contractFile';
import { Input, Modal, Button } from '@itandi/itandi-bb-ui';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  inputs: ContractFileParams;
  handleFileUpload: () => Promise<void>;
  handleClose: () => void;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}>;

export const ContractsFilesUploadModal: React.FC<Props> = ({
  inputs,
  handleFileUpload,
  handleClose,
  isOpen,
  handleTitleChange,
  handleFileChange
}: Props) => (
  <Modal
    handleClose={handleClose}
    isOpen={isOpen}
    title="ファイル追加"
    useDefaultFooter={false}
  >
    <div>30ファイルまでアップロード可能です</div>
    <div className={Styles.InputLabel}>タイトル</div>
    <Input onChange={handleTitleChange} value={inputs.title} />
    <div className={Styles.InputLabel}>ファイル</div>
    <Input
      accept="image/*, .pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/msword, application/vnd.ms-powerpoint"
      className={Styles.FileInput}
      onChange={handleFileChange}
      type="file"
    />
    <Button
      className={Styles.Button}
      disabled={!inputs.title || !inputs.file}
      height="small"
      onClick={handleFileUpload}
      variant="secondary"
    >
      追加
    </Button>
  </Modal>
);
