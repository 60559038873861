import React from 'react';
import { displayName, Owner } from '~/models/owner';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  index: number;
  owner: Owner;
}>;

export const ContractsEditorOwnerSelectorSelectedRow: React.FC<Props> = ({
  index,
  owner
}: Props) => {
  const { getValues, setValue } =
    useFormContext<DeepReadonly<ContractForEdit>>();
  const onClickDelete = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (e: React.MouseEvent): void => {
      e.preventDefault();
      const currentOwners = getValues('owners');
      const ownerIndex = currentOwners.findIndex(
        (o: DeepReadonly<Owner>) => o.id === owner.id
      );
      const newOwners = [...currentOwners];
      newOwners.splice(ownerIndex, 1);
      setValue('owners', newOwners);
    },
    [getValues, setValue, owner]
  );
  return (
    <div key={owner.id} className={Styles.SelectedRow}>
      <div className={Styles.NameBlock}>
        <span className={Styles.OwnerKey}>
          {index === 0 && <span>[代表]</span>}
          {owner.ownerKey}
        </span>
        <span className={Styles.OwnerName}>{displayName(owner)}</span>
      </div>
      <div className={Styles.Buttons}>
        <a className={Styles.DeleteLink} href="#" onClick={onClickDelete}>
          解除
        </a>
      </div>
    </div>
  );
};
