import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const Breadcrumbs: React.FC<Props> = ({ children }: Props) => (
  <ol className={Styles.Breadcrumbs}>{children}</ol>
);
