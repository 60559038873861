import React from 'react';
import { displayName, Owner } from '~/models/owner';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import { RiArrowRightSLine } from 'react-icons/ri';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  handleClose: () => void;
  owner: Owner;
}>;

export const ContractsEditorOwnerSelectorModalRow: React.FC<Props> = ({
  handleClose,
  owner
}: Props) => {
  const { setValue, getValues } =
    useFormContext<DeepReadonly<ContractForEdit>>();
  const handleClickOwner = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (e: React.MouseEvent) => {
      e.preventDefault();
      const { owners } = getValues();
      const newOwners = [...owners];
      newOwners.push(owner);
      setValue('owners', newOwners);
      handleClose();
    },
    [handleClose, getValues, owner, setValue]
  );
  return (
    <li>
      <a className={Styles.OwnerLink} href="#" onClick={handleClickOwner}>
        <div>
          <div className={Styles.OwnerKey}>{owner.ownerKey}</div>
          {displayName(owner)}
        </div>
        <RiArrowRightSLine className={Styles.ArrowLink} />
      </a>
    </li>
  );
};
