import React from 'react';
import { ContractsTitleHeader } from '~/components/specifics/contracts/[id]/components/TitleHeader';
import { useRouter } from 'next/router';
import { ContractsContainer } from '~/components/specifics/contracts/[id]/components/Container';
import { useContracts } from '~/hooks/api/useContracts';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { Loader } from '~/components/common/Loader';

export const Page: React.FC = () => {
  const router = useRouter();
  const { id } = router.query;
  const {
    data: contract,
    isLoading,
    mutate
  } = useContracts().useShow(id ? Number(id) : null);
  const [loading, setLoading] = React.useState(isLoading);
  React.useEffect(() => {
    const refreshData = async (): Promise<void> => {
      setLoading(true);
      await mutate?.();
      setLoading(false);
    };

    if (id) {
      refreshData();
    }
  }, [id, mutate]);
  if (loading || contract === null) {
    return <Loader />;
  }
  return (
    <LayoutsItandiSessionLayout>
      <div>
        <ContractsTitleHeader contract={contract} />
        <ContractsContainer />
      </div>
    </LayoutsItandiSessionLayout>
  );
};
