import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useContracts } from '~/hooks/api/useContracts';
import { ContractCustomValue } from '~/models/contractCustomValue';
import { RiMoreLine } from 'react-icons/ri';
import { useCustomFieldSettings } from '~/hooks/api/useCustomFieldSettings';
import { CustomFieldSetting } from '~/models/customFieldSetting';
import { displayDate } from '~/utils/dateUtils';
import { ListItem } from '~/components/common/List/Item';
import { ListCard } from '~/components/common/List/Card';
import Styles from '../index.module.scss';

type RowProps = DeepReadonly<{
  customFieldSetting: CustomFieldSetting;
  contractCustomValues: ContractCustomValue[];
}>;

const displayBoolean = (customFieldValue: boolean): string => {
  if (customFieldValue) {
    return 'チェック済み';
  }
  return '未チェック';
};

export const CustomFieldRow: React.FC<RowProps> = ({
  customFieldSetting,
  contractCustomValues
}: RowProps) => {
  const contractCustomValue: ContractCustomValue | null = React.useMemo(
    () =>
      contractCustomValues.find(
        (customValue: DeepReadonly<ContractCustomValue>) =>
          customValue.fieldKey === customFieldSetting.fieldKey
      ) ?? null,
    [customFieldSetting, contractCustomValues]
  );
  const displayValue: string | number | null = React.useMemo(() => {
    if (contractCustomValue === null || !contractCustomValue.value) {
      return null;
    }
    switch (customFieldSetting.fieldType) {
      case 'date':
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return displayDate(contractCustomValue.value as string);
      case 'boolean':
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return displayBoolean(contractCustomValue.value as boolean);
      default:
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return contractCustomValue.value as string | number;
    }
  }, [contractCustomValue, customFieldSetting]);
  return (
    <ListItem
      content={displayValue || <span className={Styles.Empty}>未登録</span>}
      title={customFieldSetting.name}
    />
  );
};

export const ContractsContentsBasicCustomField: React.FC = () => {
  const { id } = useRouter().query;
  const { data: contract } = useContracts().useShow(id ? Number(id) : null);
  const { data: customFieldSettings } = useCustomFieldSettings().useIndex();
  if (contract === null) return null;
  if (customFieldSettings === null || customFieldSettings.length === 0) {
    return null;
  }
  return (
    <ListCard icon={<RiMoreLine />} title="補足情報">
      {customFieldSettings.map(
        (customFieldSetting: DeepReadonly<CustomFieldSetting>): ReactNode => (
          <CustomFieldRow
            key={customFieldSetting.id}
            contractCustomValues={contract.contractCustomValues}
            customFieldSetting={customFieldSetting}
          />
        )
      )}
    </ListCard>
  );
};
