import React from 'react';
import { KeyedMutator } from 'swr';
import { Modal } from '@itandi/itandi-bb-ui';
import { Contract } from '~/models/contract';
import { ContractAuthIdEditor } from '..';

// AuthIdsController update actionアクションを利用した支店変更モーダル
type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
  contract: Contract;
  contractMutate?: KeyedMutator<Contract>;
  authList: { authId: number; name: string }[];
}>;

export const ContractAuthIdEditorModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  contract,
  contractMutate,
  authList
}: Props) => (
  <Modal
    handleClose={handleClose}
    isOpen={isOpen}
    title="支店変更"
    useDefaultFooter={false}
  >
    <ContractAuthIdEditor
      authList={authList}
      contract={contract}
      contractMutate={contractMutate}
      handleClose={handleClose}
      updateActionPath="/yk/contracts/auth_ids/update"
    />
  </Modal>
);
