import { getApiHost } from '~/utils/api/apiClient';

const paths = {
  show: `${getApiHost()}/yk/contracts/:contract_id/report_templates/:report_template_id/sheet_excel_exporters`
};

export const getShowUrl = (
  contractId: number,
  reportTemplateId: number
): string =>
  `${paths.show
    .replace(':contract_id', String(contractId))
    .replace(':report_template_id', String(reportTemplateId))}`;
