import React, { ReactNode } from 'react';
import { useItandiAccountSessions } from '~/hooks/api/useItandiAccountSessions';
import { LayoutsItandiHeaderLayout } from '../ItandiHeaderLayout';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const LayoutsItandiSessionLayout: React.FC<Props> = ({
  children
}: Props) => {
  const { data: itandiSession } = useItandiAccountSessions().useShow();
  if (itandiSession === null) {
    return null;
  }
  return <LayoutsItandiHeaderLayout>{children}</LayoutsItandiHeaderLayout>;
};
