import React from 'react';
import { Pagination } from '@itandi/itandi-bb-ui';
import { useContractHistories } from '~/hooks/api/useContractHistories';
import { useRouter } from 'next/router';
import { ContractHistoriesListTableBody } from '~/components/specifics/contracts/[id]/histories/components/ListTable/Body';
import { ContractHistoriesListTableHeader } from '~/components/specifics/contracts/[id]/histories/components/ListTable/Header';
import { Contract } from '~/models/contract';
import { ContractHistory } from '~/models/contractHistory';
import { PATH } from '~/pages/contracts/[id]/histories/index';
import { TableDisplayCount } from '~/components/common/Table/DisplayCount/index';
import { TableContainer } from '~/components/common/Table/Container';
import ContractListStyles from '../../../../components/List/index.module.scss';

type Props = DeepReadonly<{
  contract: Contract;
}>;

export const ContractHistoriesListTable: React.FC<Props> = ({
  contract
}: Props) => {
  const router = useRouter();
  const { id } = router.query;
  const { data: contractHistories } = useContractHistories().useIndex(
    id ? Number(id) : null
  );
  const handleChangePage = React.useCallback(
    (newPageNumber: number): void => {
      const newQuery = newPageNumber === 1 ? {} : { page: newPageNumber };
      router.push({ pathname: PATH(Number(id)), query: newQuery });
    },
    [router, id]
  );
  return contractHistories !== null ? (
    <div>
      <TableDisplayCount
        count={contractHistories.count}
        currentPage={contractHistories.currentPage}
        itemsPerPage={10}
      />
      <TableContainer>
        <ContractHistoriesListTableHeader />
        {contractHistories.objects.map(
          (contractHistory: DeepReadonlyObject<ContractHistory>) => (
            <ContractHistoriesListTableBody
              key={contractHistory.id}
              contract={contract}
              contractHistory={contractHistory}
            />
          )
        )}
      </TableContainer>
      <div className={ContractListStyles.TablePagination}>
        <Pagination
          currentPageNum={contractHistories.currentPage}
          handleChangePage={handleChangePage}
          maxPageNum={contractHistories.sumPageCount}
        />
      </div>
    </div>
  ) : null;
};
