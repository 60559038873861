import React from 'react';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import { useRouter } from 'next/router';
import { PATH as OWNERS_PATH } from '~/pages/owners';
import { PATH as OWNER_PATH } from '~/pages/owners/[id]';
import { OwnersEditor } from '~/components/common/Owners/Editor';
import { useOwners } from '~/hooks/api/useOwners';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';

export const Page: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  return (
    <LayoutsItandiSessionLayout>
      <LayoutsContainer
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem href={OWNERS_PATH}>家主一覧</BreadcrumbsItem>
            <BreadcrumbsItem href={OWNER_PATH(Number(id))}>
              家主詳細
            </BreadcrumbsItem>
            <BreadcrumbsItem isActive>家主情報の修正</BreadcrumbsItem>
          </Breadcrumbs>
        }
        fullBodyWidth
        title="家主情報の修正"
      >
        {owner !== null && <OwnersEditor owner={owner} />}
      </LayoutsContainer>
    </LayoutsItandiSessionLayout>
  );
};
