import React, { ReactNode } from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale
} from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import { Portal } from 'react-overlays';
import { RiCalendarLine } from 'react-icons/ri';
import Styles from './index.module.scss';

registerLocale('ja', ja);
type Props = ReactDatePickerProps & DeepReadonly<{ popperDefault?: boolean }>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CalenderContainer = ({ children }: any): ReactNode => {
  if (typeof document !== 'undefined') {
    const el = document.body;
    return <Portal container={el}>{children}</Portal>;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const InputDate: React.FC<Props> = (props: Props) => {
  const { showTimeSelect, popperDefault } = props;
  return (
    <div className={Styles.DatePickerWrapper}>
      <RiCalendarLine className={Styles.Icon} />
      <DatePicker
        dateFormat={`yyyy/MM/dd${showTimeSelect ? ' HH:mm' : ''}`}
        locale="ja"
        popperContainer={popperDefault ? undefined : CalenderContainer}
        scrollableYearDropdown
        showYearDropdown
        yearDropdownItemNumber={130}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};
