import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children: ReactNode;
}>;

export const TableContainer: React.FC<Props> = ({ children }: Props) => (
  <table className={Styles.TableContainer}>{children}</table>
);
