import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { ContractFile } from '~/models/contractFile';

const paths = {
  index: '/contracts/:contract_id/contract_files'
};
type TIndex = (
  contractId: number
) => SwrReturnType<DeepReadonlyArray<ContractFile>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useContractFiles = (): ReturnType => {
  const useIndex: TIndex = (contractId: number) => {
    const { data, mutate, error, isValidating } = useSWR<
      DeepReadonlyArray<ContractFile>
    >(paths.index.replace(':contract_id', String(contractId)));
    return {
      data: data ?? null,
      mutate,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
