import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { RentParking } from '~/models/rentParking';

const paths = {
  index: '/kensakukun/rent_parkings'
};
type TIndex = (name: string) => SwrReturnType<ReadonlyArray<RentParking>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useRentParkings = (): ReturnType => {
  const useIndex: TIndex = (name: string) => {
    const { data, error, isValidating } = useSWR<{
      rentParkings: ReadonlyArray<RentParking>;
    }>(name ? `${paths.index}?name=${name}` : null);
    return {
      data: data ? data.rentParkings : null,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
