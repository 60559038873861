import { SwrReturnType } from '~/models/api';
import useSWR from 'swr';
import { ItandiUserSession } from '~/models/itandiUserSession';

const paths = {
  show: '/itandi_user_sessions'
};

type TShow = () => SwrReturnType<ItandiUserSession>;
type ReturnType = Readonly<{
  useShow: TShow;
}>;

export const useItandiUserSessions = (): ReturnType => {
  const useShow: TShow = () => {
    const { data, mutate, error } = useSWR<ItandiUserSession>(paths.show);
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useShow };
};
