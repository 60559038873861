import React, { useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import {
  defaultOwnerValue,
  Owner,
  OwnerForEdit,
  validationSchema
} from '~/models/owner';
import { OwnersEditorFooter } from '~/components/specifics/owners/components/Editor/Footer';
import { OwnersEditorBlockBasic } from '~/components/specifics/owners/components/Editor/Block/Basic';
import { OwnersEditorBlockCorp } from '~/components/specifics/owners/components/Editor/Block/Corp';
import { OwnersEditorBlockContact } from '~/components/specifics/owners/components/Editor/Block/Contact';
import { OwnersEditorBlockOther } from '~/components/specifics/owners/components/Editor/Block/Other';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOwnerCustomFieldSettings } from '~/hooks/api/useOwnerCustomFieldSettings';
import { OwnerCustomValue } from '~/models/ownerCustomValue';
import { OwnersEditorBlockCustomField } from '~/components/specifics/owners/components/Editor/Block/OwnerCustomField';
import { OwnersEditorBlockAntisocialCheck } from '../../../specifics/owners/components/Editor/Block/AntisocialCheck';
import Styles from './index.module.scss';
import { OwnersEditorBlockIndividual } from '../../../specifics/owners/components/Editor/Block/Individual';

type Props = DeepReadonly<{
  owner?: Owner | null;
}>;

export type EditorMode = 'new' | 'edit';

export const OwnersEditor: React.FC<Props> = ({ owner = null }: Props) => {
  const editorMode: EditorMode = React.useMemo(
    () => (owner === null ? 'new' : 'edit'),
    [owner]
  );
  const { data: ownerCustomFieldSettings } =
    useOwnerCustomFieldSettings().useIndex();
  const methods: UseFormReturn<DeepReadonly<OwnerForEdit>> = useForm<
    DeepReadonly<OwnerForEdit>
  >({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: owner
      ? {
          ...owner,
          ownerCustomFieldValues: owner?.ownerCustomValues.reduce(
            (
              acc: Readonly<{ [field in string]: string | boolean | number }>,
              currentValue: DeepReadonly<OwnerCustomValue>
            ) => ({
              ...acc,
              [currentValue.fieldKey]: currentValue.value
            }),
            {}
          )
        }
      : defaultOwnerValue()
  });
  const { watch } = methods;

  const [isFullNameForm, setIsFullNameForm] = useState<boolean>(false);

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <div className={Styles.FormContainer}>
          <OwnersEditorBlockBasic />
          {watch('corp') ? (
            <OwnersEditorBlockCorp />
          ) : (
            <OwnersEditorBlockIndividual
              isFullNameForm={isFullNameForm}
              setIsFullNameForm={setIsFullNameForm}
            />
          )}
          <OwnersEditorBlockContact />
          <OwnersEditorBlockAntisocialCheck />
          <OwnersEditorBlockOther />
          {ownerCustomFieldSettings !== null && (
            <OwnersEditorBlockCustomField
              ownerCustomFieldSettings={ownerCustomFieldSettings}
            />
          )}
        </div>
        <OwnersEditorFooter
          editorMode={editorMode}
          isFullNameForm={isFullNameForm}
        />
      </FormProvider>
    </div>
  );
};
