import React from 'react';
import { Input, Modal, Button } from '@itandi/itandi-bb-ui';
import { RentParking } from '~/models/rentParking';
import { RentParkingSpace } from '~/models/rentParkingSpace';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useRentParkings } from '~/hooks/api/useRentParkings';
import { useRentParkingSpaces } from '~/hooks/api/useRentParkingSpaces';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  isOpen: boolean;
  handleClose: () => void;
}>;

export const ContractsEditorParkingSelectorModal: React.FC<Props> = ({
  isOpen,
  handleClose
}: Props) => {
  // 駐車場検索

  // フォームの入力値
  const [inputName, setInputName] = React.useState<string>('');
  // APIリクエスト用の建物名
  const [searchName, setSearchName] = React.useState<string>('');
  const {
    data: rentParkings,
    isLoading: rentParkingsLoading,
    error: rentParkingsError
  } = useRentParkings().useIndex(searchName);
  const [searchedRentParkings, setSearchedRentParkings] =
    React.useState<boolean>(false);

  // 選択した駐車場
  const [selectedRentParking, setSelectedRentParking] =
    React.useState<RentParking | null>(null);

  // 駐車場区画検索
  const [parkingPropertyId, setParkingPropertyId] = React.useState<
    number | null
  >(null);
  const {
    data: rentParkingSpaces,
    isLoading: rentParkingSpacesLoading,
    error: rentParkingSpacesError
  } = useRentParkingSpaces().useIndex(parkingPropertyId);

  const { setValue } = useFormContext<DeepReadonly<ContractForEdit>>();

  const handleNameChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputName(e.target.value);
  };

  const handleSearchSubmit = async (): Promise<void> => {
    setSearchedRentParkings(true);
    setSearchName(inputName);
  };

  const handleRentParkingSelected = async (
    rentParking: RentParking
  ): Promise<void> => {
    setSelectedRentParking(rentParking);
    setParkingPropertyId(rentParking.propertyId);
  };

  const handlePropertySelected = (
    property: RentParking | RentParkingSpace
  ): void => {
    setValue('property', {
      kensakukunPropertyId: property.propertyId,
      name: property.name,
      roomNumber: null,
      parkingSpaceNumber: 'number' in property ? property.number : null,
      propertyType: property.propertyType
    });
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title={selectedRentParking ? '駐車場区画番号を選択' : '駐車場を選択'}
      useDefaultFooter={false}
    >
      {!selectedRentParking ? (
        <>
          <div>
            <Input onChange={handleNameChange} value={inputName} />
            <div className={Styles.ButtonWrapper}>
              <Button
                height="small"
                onClick={handleSearchSubmit}
                variant="primary"
              >
                検索する
              </Button>
            </div>
          </div>
          {!rentParkingsLoading && rentParkings && (
            <ul className={Styles.List}>
              {rentParkings.map((rentParking: RentParking) => (
                <li key={rentParking.propertyId}>
                  <button
                    className={Styles.Link}
                    onClick={(): Promise<void> =>
                      handleRentParkingSelected(rentParking)
                    }
                    type="button"
                  >
                    <div className={Styles.Key}>
                      {rentParking.name} {rentParking.address}
                    </div>
                    <RiArrowRightSLine className={Styles.ArrowLink} />
                  </button>
                </li>
              ))}
            </ul>
          )}
          {searchedRentParkings &&
            !rentParkingsLoading &&
            rentParkings?.length === 0 && (
              <div className={Styles.FetchResultText}>検索結果が0件です</div>
            )}
          {rentParkingsLoading && (
            <div className={Styles.FetchResultText}>ロード中</div>
          )}
          {rentParkingsError && (
            <div className={Styles.FetchResultText}>
              データ取得中にエラーが発生しました
            </div>
          )}
        </>
      ) : (
        <ul className={Styles.List}>
          <button
            className={Styles.BackSelectBuildingLink}
            onClick={(): void => setSelectedRentParking(null)}
            type="button"
          >
            駐車場選択に戻る
          </button>
          {!rentParkingSpacesLoading && rentParkingSpaces && (
            <>
              <li key="一棟">
                <button
                  className={Styles.Link}
                  onClick={(): void =>
                    handlePropertySelected(selectedRentParking)
                  }
                  type="button"
                >
                  <div className={Styles.Key}>駐車場ごと選択</div>
                  <RiArrowRightSLine className={Styles.ArrowLink} />
                </button>
              </li>
              {rentParkingSpaces.map((rentParkingSpace: RentParkingSpace) => (
                <li key={rentParkingSpace.propertyId}>
                  <button
                    className={Styles.Link}
                    onClick={(): void =>
                      handlePropertySelected(rentParkingSpace)
                    }
                    type="button"
                  >
                    <div className={Styles.Key}>{rentParkingSpace.number}</div>
                    <RiArrowRightSLine className={Styles.ArrowLink} />
                  </button>
                </li>
              ))}
            </>
          )}
          {rentParkingSpacesLoading && (
            <div className={Styles.FetchResultText}>ロード中</div>
          )}
          {rentParkingSpacesError && (
            <div className={Styles.FetchResultText}>
              データ取得中にエラーが発生しました
            </div>
          )}
        </ul>
      )}
    </Modal>
  );
};
