import React from 'react';

type ReturnType = (defaultState?: boolean) => DeepReadonly<{
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}>;

export const useOpenHandler: ReturnType = (defaultState = false) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultState);
  const handleOpen = React.useCallback(() => setIsOpen(true), []);
  const handleClose = React.useCallback(() => setIsOpen(false), []);
  return { isOpen, handleOpen, handleClose };
};
