import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { addressNumbers, getAddress, Owner } from '~/models/owner';
import { ListItem } from '~/components/common/List/Item';
import Styles from '../index.module.scss';

const getAddressNodes = (owner: Owner): ReactNode[] => {
  const result: ReactNode[] = [];
  addressNumbers.forEach((num: 1 | 2 | 3): ReactNode => {
    const address = getAddress(owner, num);
    if (address === null) {
      return;
    }
    result.push(
      <div key={num} className={Styles.AddressBlock}>
        {num === Number(owner?.shippingAddressIndex) && (
          <div className={Styles.Additional}>【メインの送付先】</div>
        )}
        <div>〒{address.zipcode}</div>
        <div>
          {address.state} {address.city} {address.street} {address.other}
        </div>
        <div>{address.note}</div>
      </div>
    );
  });
  return result;
};

export const OwnersContentsBasicAddress: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }
  return (
    <ListItem
      content={
        <div className={Styles.Addresses}>
          {getAddressNodes(owner).length === 0 && (
            <span className={Styles.Empty}>未登録</span>
          )}
          {getAddressNodes(owner)}
        </div>
      }
      title="住所"
    />
  );
};
