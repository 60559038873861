import React, { ReactNode } from 'react';
import { getTel, Owner, telNumbers } from '~/models/owner';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { ListItem } from '~/components/common/List/Item';
import Styles from '../index.module.scss';

const getTelNodes = (owner: Owner): ReactNode[] => {
  const result: ReactNode[] = [];
  telNumbers.forEach((num: 1 | 2 | 3): ReactNode => {
    const tel = getTel(owner, num);
    if (tel === null || tel === '') {
      return;
    }
    result.push(
      <div key={num}>
        <span>
          <a href={`tel:${tel}`}>{tel}</a>
        </span>
        {num === 1 && (
          <span className={Styles.Additional}>（メインの連絡先）</span>
        )}
      </div>
    );
  });
  return result;
};

export const OwnersContentsBasicTel: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }
  return (
    <ListItem
      content={
        <>
          {getTelNodes(owner).length === 0 && (
            <span className={Styles.Empty}>未登録</span>
          )}
          {getTelNodes(owner)}
        </>
      }
      title="電話番号"
    />
  );
};
