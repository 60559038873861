import React from 'react';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ContractsEditorBuildingSelectorModal } from '~/components/specifics/contracts/components/Editor/BuildingSelector/Modal';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';
import Styles from './index.module.scss';

export const ContractsEditorBuildingSelector: React.FC = () => {
  const { watch } = useFormContext<DeepReadonly<ContractForEdit>>();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const property = watch('property');

  return (
    <div>
      {property.kensakukunPropertyId === null && (
        <button
          className={Styles.SelectButton}
          onClick={handleOpen}
          type="button"
        >
          建物を選択
        </button>
      )}
      <ContractsEditorBuildingSelectorModal
        handleClose={handleClose}
        isOpen={isOpen}
      />
    </div>
  );
};
