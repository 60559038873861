import React from 'react';
import {
  Contract,
  ContractForEdit,
  defaultContractValue,
  validationSchema
} from '~/models/contract';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { ContractsEditorFooter } from '~/components/specifics/contracts/components/Editor/Footer';
import { ContractsEditorBlock } from '~/components/specifics/contracts/components/Editor/Block';
import { ContractsEditorBlockOriginContractBlock } from '~/components/specifics/contracts/components/Editor/Block/OriginContractBlock';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCustomFieldSettings } from '~/hooks/api/useCustomFieldSettings';
import { ContractsEditorBlockCustomField } from '~/components/specifics/contracts/components/Editor/Block/CustomField';
import { ContractCustomValue } from '~/models/contractCustomValue';
import { ContractsEditorBlockPaymentAndInvoiceBlock } from '~/components/specifics/contracts/components/Editor/Block/PaymentAndInvoiceBlock';
import { convertFormInvoices } from '~/models/invoice';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  contract?: Contract | null;
  editorMode: EditorMode;
}>;

export type EditorMode = 'new' | 'edit' | 'renewal';

export const ContractsEditor: React.FC<Props> = ({
  contract = null,
  editorMode
}: Props) => {
  const { data: customFieldSettings } = useCustomFieldSettings().useIndex();
  const methods: UseFormReturn<DeepReadonly<ContractForEdit>> = useForm<
    DeepReadonly<ContractForEdit>
  >({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: contract
      ? {
          ...contract,
          customFieldValues: contract?.contractCustomValues.reduce(
            (
              acc: Readonly<{ [field in string]: string | boolean | number }>,
              currentValue: DeepReadonly<ContractCustomValue>
            ) => ({
              ...acc,
              [currentValue.fieldKey]: currentValue.value
            }),
            {}
          ),
          property: contract.property
            ? {
                kensakukunPropertyId: contract.property.kensakukunPropertyId,
                name: contract.property.name,
                roomNumber: contract.property.roomNumber,
                parkingSpaceNumber: contract.property.parkingSpaceNumber,
                propertyType: contract.property.propertyType
              }
            : defaultContractValue().property,
          invoices: convertFormInvoices(contract.invoices)
        }
      : defaultContractValue()
  });

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <div className={Styles.FormContainer}>
          {editorMode !== 'renewal' && (
            <ContractsEditorBlockOriginContractBlock />
          )}
          <ContractsEditorBlock editorMode={editorMode} />
          <ContractsEditorBlockPaymentAndInvoiceBlock />
          {customFieldSettings !== null && (
            <ContractsEditorBlockCustomField
              customFieldSettings={customFieldSettings}
            />
          )}
        </div>
        <ContractsEditorFooter editorMode={editorMode} />
      </FormProvider>
    </div>
  );
};
