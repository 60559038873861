import React from 'react';
import { seisanKanrikunOwnerNew } from '~/utils/foreignOrigin';
import { Input, Modal, Button } from '@itandi/itandi-bb-ui';
import { useSeisanKanrikunOwners } from '~/hooks/api/useSeisanKanrikunOwners';
import { SeisanKanrikunOwner } from '~/models/seisanKanrikunOwner';
import { update } from '~/utils/api/resources/seisanKanrikunOwner';
import { OwnerWithContracts, displayName } from '~/models/owner';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { KeyedMutator } from 'swr';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  collaboratedSeisanKanrikunOwnerMutate?: KeyedMutator<
    ReadonlyArray<SeisanKanrikunOwner>
  >;
  isOpen: boolean;
  handleClose: () => void;
  owner: OwnerWithContracts;
}>;

export const OwnersCollaborationModal: React.FC<Props> = ({
  collaboratedSeisanKanrikunOwnerMutate,
  isOpen,
  handleClose,
  owner
}: Props) => {
  // フォーム用のオーナー名
  const [inputOwnerName, setInputOwnerName] = React.useState<string>('');
  const handleOwnerNameChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputOwnerName(e.target.value);
  };

  // フォーム用の管理対象の名前
  const [inputPropertyName, setInputPropertyName] = React.useState<string>('');
  const handlePropertyNameChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputPropertyName(e.target.value);
  };

  // APIリクエスト用の値
  const [searchPropertyName, setSearchPropertyName] =
    React.useState<string>('');
  const [searchOwnerName, setSearchOwnerName] = React.useState<string>('');

  // 一度でも検索をしたか確認
  const [searchedSeisanKanrikunOwners, setSearchedSeisanKanrikunOwners] =
    React.useState<boolean>(false);

  // 検索
  const {
    data: seisanKanrikunOwners,
    isLoading: seisanKanrikunOwnersLoading,
    error: seisanKanrikunOwnersError
  } = useSeisanKanrikunOwners().useIndex({
    name: searchOwnerName,
    propertyName: searchPropertyName
  });

  const handleSearchSubmit = async (): Promise<void> => {
    setSearchedSeisanKanrikunOwners(true);
    setSearchOwnerName(inputOwnerName);
    setSearchPropertyName(inputPropertyName);
  };

  // 連携
  const handleCollaborate = async (
    seisanKanrikunOwner: SeisanKanrikunOwner
  ): Promise<void> => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `精算管理くん上のオーナー ${seisanKanrikunOwner.name}と連携して良いですか？`
      )
    ) {
      const response = await update(seisanKanrikunOwner, owner.id);
      if (isSuccess(response)) {
        collaboratedSeisanKanrikunOwnerMutate?.();
        handleClose();
        toast.success('連携に成功しました');
        return;
      }
      toast.error('連携に失敗しました');
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      title="オーナー連携"
      useDefaultFooter={false}
    >
      <div className={Styles.Form}>
        <div className={Styles.InputLabel}>精算管理くん上でのオーナー名</div>
        <Input onChange={handleOwnerNameChange} value={inputOwnerName} />
        <div className={Styles.InputLabel}>
          オーナーに関連する管理委託契約の管理資産名
        </div>
        <Input onChange={handlePropertyNameChange} value={inputPropertyName} />
        <Button
          className={Styles.SearchButton}
          disabled={!inputOwnerName && !inputPropertyName}
          height="small"
          onClick={handleSearchSubmit}
          variant="primary"
        >
          検索する
        </Button>
      </div>
      {!seisanKanrikunOwnersLoading && seisanKanrikunOwners && (
        <div className={Styles.List}>
          {seisanKanrikunOwners.map(
            (seisanKanrikunOwner: SeisanKanrikunOwner) => (
              <div
                key={seisanKanrikunOwner.id}
                className={Styles.SeisanKanrikunOwner}
              >
                <div>{seisanKanrikunOwner.name}</div>
                <button
                  className={Styles.CollaborateButton}
                  onClick={(): Promise<void> =>
                    handleCollaborate(seisanKanrikunOwner)
                  }
                  type="button"
                >
                  連携
                </button>
              </div>
            )
          )}
        </div>
      )}
      {searchedSeisanKanrikunOwners &&
        !seisanKanrikunOwnersLoading &&
        seisanKanrikunOwners?.length === 0 && (
          <div className={Styles.FetchResultText}>検索結果が0件です</div>
        )}
      {seisanKanrikunOwnersLoading && (
        <div className={Styles.FetchResultText}>ロード中</div>
      )}
      {seisanKanrikunOwnersError && (
        <div className={Styles.FetchResultText}>
          データ取得中にエラーが発生しました
        </div>
      )}
      <a
        className={Styles.Link}
        href={`${seisanKanrikunOwnerNew}?ykOwnerId=${
          owner.id
        }&name=${displayName(owner)}`}
      >
        精算管理くんでオーナーを新規登録
      </a>
    </Modal>
  );
};
