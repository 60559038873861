import React from 'react';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { useUsingSeisanKanrikun } from '~/hooks/useUsingSeisanKanrikun';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';
import { SettingsListMenuList } from './components/List/MenuList';

// NOTE: 【/roles】で循環参照を起こしてしまうので、個別に定義している
const ROLES_PATH = '/roles';
// NOTE: 【/company_settings】で循環参照を起こしてしまうので、個別に定義している
const COMPANY_SETTINGS_PATH = '/company_settings';

const settings = [
  {
    title: '会社設定',
    description: '家主管理くん全体に関わる会社ごとの設定をします',
    path: COMPANY_SETTINGS_PATH
  },
  {
    title: '権限管理',
    description: '担当者毎の権限の確認・編集をします',
    path: ROLES_PATH
  },
  {
    title: '契約書のテンプレート',
    description: '会社ごとに契約書のテンプレートの設定をします',
    path: '/templates'
  },
  {
    title: '精算管理くんと連携する際の費目設定',
    description: '会社ごとに精算管理くんと連携する際の費目設定をします',
    path: '/goods_title_mappings'
  },
  {
    title: '管理委託契約カスタム項目の設定',
    description: '会社ごとに管理委託契約に紐付ける独自の項目の設定をします',
    path: '/custom_field_settings'
  },
  {
    title: 'オーナーカスタム項目の設定',
    description: '会社ごとにオーナーに紐付ける独自の項目の設定をします',
    path: '/owner_custom_field_settings'
  }
];

export const Page: React.FC = () => {
  const usingSeisanKanrikun = useUsingSeisanKanrikun();
  const displaySettings = usingSeisanKanrikun
    ? settings
    : settings.filter(
        (
          setting: DeepReadonly<{
            title: string;
            description: string;
            path: string;
          }>
        ) => setting.title !== '精算管理くんと連携する際の費目設定' // 精算管理くんを利用していないユーザーには導線を表示しない。
      );

  return (
    <LayoutsItandiSessionLayout>
      <LayoutsContainer title="設定">
        <SettingsListMenuList settings={displaySettings} />
      </LayoutsContainer>
    </LayoutsItandiSessionLayout>
  );
};
