import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/owners/[id]/edit';

export const PATH = (id: number): string => `/owners/${id}/edit`;

const NewOwner: NextPage = () => (
  <>
    <HeadsYkHead title="家主情報の修正" />
    <Component />
  </>
);

export default NewOwner;
