import React, { ReactNode } from 'react';
import { GlobalHeader } from '~/components/common/Headers/Global';
import { useItandiUserSessions } from '~/hooks/api/useItandiUserSessions';
import { PATH } from '~/pages/signup/index';
import { useRouter } from 'next/router';

type Props = DeepReadonly<{
  children: ReactNode;
}>;
export const LayoutsItandiHeaderLayout: React.FC<Props> = ({
  children
}: Props) => {
  const { data: itandiUserSession } = useItandiUserSessions().useShow();
  const router = useRouter();

  // itandiUserSessionはページ読み込み時はnullなので、早期リターンでサインアップのリダイレクトを防止
  if (itandiUserSession === null) {
    return null;
  }

  // バックエンドでスタッフレコードがまだ作成されていなかったらサインアップページに移動
  if (!itandiUserSession?.staff) {
    router.replace(PATH);
  }

  return itandiUserSession?.staff ? (
    <div>
      <GlobalHeader />
      <main>{children}</main>
    </div>
  ) : null;
};
