import React from 'react';
import { RiFileDownloadLine } from 'react-icons/ri';
import { useReportTemplates } from '~/hooks/api/useReportTemplate';
import { TemplatesListTable } from '~/components/common/Templates/ListTable';
import { ListCard } from '~/components/common/List/Card';

export const ContractsPrintContents: React.FC = () => {
  const { data: reportTemplates } = useReportTemplates().useIndex();

  if (reportTemplates === null) {
    return null;
  }

  return (
    <ListCard icon={<RiFileDownloadLine />} title="帳票出力">
      <TemplatesListTable isExport reportTemplates={reportTemplates} />
    </ListCard>
  );
};
