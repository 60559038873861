import React from 'react';
import { useRouter } from 'next/router';
import { useContractHistories } from '~/hooks/api/useContractHistories';
import { RiDraftLine } from 'react-icons/ri';
import { contractTypeLabels } from '~/models/contract';
import { ListItem } from '~/components/common/List/Item';
import { ListCard } from '~/components/common/List/Card';
import { TextWithUnitString } from '~/components/common/Text/WithUnit/String/index';
import { TextDate } from '~/components/common/Text/Date/index';
import { TextInvoice } from '~/components/common/Text/Invoice/index';
import Styles from '../../../../components/Contents/Basic/index.module.scss';

export const ContractHistoriesContent: React.FC = () => {
  const router = useRouter();
  const { id, historyId } = router.query;
  const { data: contract } = useContractHistories().useShow(
    id ? Number(id) : null,
    historyId ? Number(historyId) : null
  );
  return contract !== null ? (
    <ListCard icon={<RiDraftLine />} title="契約情報履歴">
      <ListItem
        content={contractTypeLabels[contract.contractType]}
        title="契約種別"
      />
      <ListItem
        content={
          <>
            <TextDate dateStr={contract.startDate} />
            <span className={Styles.TermSeparator}>-</span>
            <TextDate dateStr={contract.endDate} />
          </>
        }
        title="契約期間"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={String(contract.conditionFixedPeriod ?? '')}
            unit="ヶ月"
          />
        }
        title="条件固定期間"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={String(contract.conditionUpdateCycle ?? '')}
            unit="ヶ月"
          />
        }
        title="条件変更周期"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={String(contract.revisionPeriod ?? '')}
            unit="ヶ月"
          />
        }
        title="改定期間"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={String(contract.lowerLimitOfRentRevisionRate ?? '')}
            unit="％"
          />
        }
        title="家賃改定割合の下限"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={String(contract.lowerLimitOfRentRevisionRateDuration ?? '')}
            unit="年"
          />
        }
        title="家賃改定割合の下限の有効年数"
      />
      <ListItem
        content={
          (contract.remittanceDateType === 'specify_date' && '指定日') ||
          (contract.remittanceDateType === 'last_day' && '月末') ||
          '選択なし'
        }
        title="送金日"
      />
      {contract.remittanceDateType === 'specify_date' && (
        <ListItem
          content={contract.remittanceDateSpecify || '未登録'}
          title="送金指定日"
        />
      )}
      <ListItem
        content={<TextDate dateStr={contract.remittanceStartDate} />}
        title="送金開始日"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={String(contract.numberOfManagedProperties ?? '')}
            unit="戸"
          />
        }
        title="管理戸数"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={String(contract.managementReportingMonth ?? '')}
            unit="月"
          />
        }
        title="管理レポートの報告月"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={contract.subLeaseRent ? `${contract.subLeaseRent}` : null}
            unit="円(税抜)"
          />
        }
        title="サブリース賃料"
      />
      <ListItem
        content={
          <TextWithUnitString
            str={
              contract.subLeaseParkingRent
                ? `${contract.subLeaseParkingRent}`
                : null
            }
            unit="円(税抜)"
          />
        }
        title="サブリース駐車場賃料"
      />
      <ListItem
        content={
          <TextInvoice
            feeType="new_contract_commission"
            invoices={contract.invoices}
          />
        }
        title="新規契約手数料"
      />
      <ListItem
        content={
          <TextInvoice
            feeType="renewal_commission"
            invoices={contract.invoices}
          />
        }
        title="更新時手数料"
      />
      <ListItem
        content={
          <TextInvoice
            feeType="cancellation_commission"
            invoices={contract.invoices}
          />
        }
        title="解約時手数料"
      />
    </ListCard>
  ) : null;
};
