import React from 'react';
import { TableHead } from '~/components/common/Table/Head';
import { TableRow } from '~/components/common/Table/Row';
import { TableHeader } from '~/components/common/Table/Header';

export const ContractsListTableHeader: React.FC = () => (
  <TableHead>
    <TableRow>
      <TableHeader width={10}>状態</TableHeader>
      <TableHeader width={30}>管理対象</TableHeader>
      <TableHeader width={15}>支店名</TableHeader>
      <TableHeader width={10}>契約種別</TableHeader>
      <TableHeader width={15}>家主名/会社名</TableHeader>
      <TableHeader width={30}>契約期間</TableHeader>
      <TableHeader width={18}>原契約開始日</TableHeader>
    </TableRow>
  </TableHead>
);
