import React, { ReactNode } from 'react';
import {
  displayName,
  displayNameKana,
  OwnerWithContracts
} from '~/models/owner';
import Link from 'next/link';
import { PATH as OWNER_PATH } from '~/pages/owners/[id]';
import { PATH as CONTRACT_PATH } from '~/pages/contracts/[id]';
import { displayDateTime } from '~/utils/dateUtils';
import { Contract } from '~/models/contract';
import { TableRow } from '~/components/common/Table/Row';
import { TableData } from '~/components/common/Table/Data';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  owner: OwnerWithContracts;
}>;

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const ContactInfo: React.FC<{ items: DeepReadonlyArray<string | null> }> = ({
  items
}) => {
  const filteredItems = items.filter((item): item is string => !!item);

  return (
    <div>
      {filteredItems.length > 0 ? (
        filteredItems.map((item) => <p key={item}>{item}</p>)
      ) : (
        <span className={Styles.Empty}>(登録なし)</span>
      )}
    </div>
  );
};

export const OwnersListTableBodyRow: React.FC<Props> = ({ owner }: Props) => (
  <TableRow isInTableBody>
    <TableData isVertical width={8}>
      {owner.ownerKey ? (
        <span>{owner.ownerKey}</span>
      ) : (
        <span className={Styles.Empty}>(番号なし)</span>
      )}
    </TableData>
    <TableData isVertical width={20}>
      <div>
        <Link href={OWNER_PATH(owner.id)}>
          <a>{displayName(owner)}</a>
        </Link>
        <p>{displayNameKana(owner)}</p>
      </div>
    </TableData>
    <TableData isVertical width={20}>
      <ContactInfo items={[owner.tel1, owner.tel2, owner.tel3]} />
    </TableData>
    <TableData isVertical width={20}>
      <ContactInfo items={[owner.email1, owner.email2, owner.email3]} />
    </TableData>
    <TableData isVertical width={20}>
      {owner.contracts.length === 0 && (
        <span className={Styles.Empty}>(未登録)</span>
      )}
      {owner.contracts.map(
        (contract: DeepReadonly<Contract>): ReactNode => (
          <div key={contract.id}>
            <Link href={CONTRACT_PATH(contract.id)}>
              <a>
                {contract.property ? contract.property.displayName : '未登録'}
              </a>
            </Link>
          </div>
        )
      )}
    </TableData>
    <TableData isVertical width={8}>
      {owner.staff ? (
        <span>{owner.staff.name}</span>
      ) : (
        <span className={Styles.Empty}>(未登録)</span>
      )}
    </TableData>
    <TableData isVertical width={9}>
      {displayDateTime(owner.createdAt)}
    </TableData>
  </TableRow>
);
