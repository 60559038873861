import React from 'react';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import { PATH } from '~/pages/owners';
import { OwnersEditor } from '~/components/common/Owners/Editor';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';

export const Page: React.FC = () => (
  <LayoutsItandiSessionLayout>
    <LayoutsContainer
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbsItem href={PATH}>家主一覧</BreadcrumbsItem>
          <BreadcrumbsItem isActive>家主の新規登録</BreadcrumbsItem>
        </Breadcrumbs>
      }
      fullBodyWidth
      title="家主の新規登録"
    >
      <OwnersEditor />
    </LayoutsContainer>
  </LayoutsItandiSessionLayout>
);
