import React from 'react';
import { NextPage } from 'next';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';
import { Page as Component } from '~/components/specifics/owners';

export const PATH = '/owners';

const Owners: NextPage = () => (
  <>
    <HeadsYkHead title="家主一覧" />
    <Component />
  </>
);

export default Owners;
