import React from 'react';
import { ContractContents } from '~/components/specifics/contracts/[id]/components/Contents';
import { ContractsPageTabs } from '~/components/specifics/contracts/[id]/components/PageTabs';
import { useContracts } from '~/hooks/api/useContracts';
import { useRouter } from 'next/router';
import Styles from './index.module.scss';

export type TabMode = 'basic' | 'remitting' | 'process' | 'print' | 'files';

export const ContractsContainer: React.FC = () => {
  const [tabMode, setTabMode] = React.useState<TabMode>('basic');
  const handleChangeTabMode = React.useCallback((newTabMode: TabMode): void => {
    setTabMode(newTabMode);
  }, []);
  const router = useRouter();
  const { id } = router.query;
  const { data: contract, mutate } = useContracts().useShow(
    id ? Number(id) : null
  );
  if (contract === null) {
    return null;
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.TabArea}>
        {/* <ContractPageVerticalTabs */}
        {/*  currentTabMode={tabMode} */}
        {/*  handleChangeTabMode={handleChangeTabMode} */}
        {/* /> */}
        <ContractsPageTabs
          contract={contract}
          currentTabMode={tabMode}
          handleChangeTabMode={handleChangeTabMode}
        />
      </div>
      <div className={Styles.Contents}>
        <ContractContents
          contract={contract}
          mutate={mutate}
          tabMode={tabMode}
        />
      </div>
    </div>
  );
};
