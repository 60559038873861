import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { TabMode } from '~/components/specifics/owners/[id]/components/Container';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useUsingSeisanKanrikun } from '~/hooks/useUsingSeisanKanrikun';
import { OwnerWithContracts } from '~/models/owner';
import Styles from './index.module.scss';

import { OwnersSeisanKanrikunCollaborationTab } from '../CollaborationTab/SeisanKanrikun';

type TabProps = DeepReadonly<{
  isActive: boolean;
  onClick: () => void;
  children: string;
  disabled: boolean;
}>;

const OwnerPageTab: React.FC<TabProps> = ({
  isActive,
  onClick,
  children,
  disabled
}: TabProps) => (
  <div
    className={clsx(
      Styles.Tab,
      isActive && Styles.Active,
      disabled && Styles.Disabled
    )}
  >
    <button
      className={Styles.TabButton}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {children}
      <RiArrowRightSLine className={Styles.RightArrow} />
    </button>
  </div>
);

type Props = DeepReadonly<{
  currentTabMode: TabMode;
  handleChangeTabMode: (newTabMode: TabMode) => void;
  owner: OwnerWithContracts;
}>;

type TabInfo = {
  tabMode: TabMode;
  caption: string;
  disabled: boolean;
};

const tabs: TabInfo[] = [
  { tabMode: 'basic', caption: '基本情報', disabled: false },
  { tabMode: 'contracts', caption: '管理委託契約', disabled: true },
  { tabMode: 'contacts', caption: '応対履歴', disabled: true },
  { tabMode: 'bank_accounts', caption: '口座情報', disabled: true },
  { tabMode: 'prints', caption: '帳票出力', disabled: true },
  { tabMode: 'files', caption: 'ファイル', disabled: true }
];

export const OwnersPageTabs: React.FC<Props> = ({
  currentTabMode,
  handleChangeTabMode,
  owner
}: Props) => {
  const usingSeisanKanrikun = useUsingSeisanKanrikun();

  const renderTab = React.useCallback(
    (tab: Readonly<TabInfo>): ReactNode =>
      // 現状基本情報しか使用していないので、他は非表示にする
      !tab.disabled ? (
        <OwnerPageTab
          key={tab.tabMode}
          disabled={tab.disabled}
          isActive={currentTabMode === tab.tabMode}
          onClick={(): void => handleChangeTabMode(tab.tabMode)}
        >
          {tab.caption}
        </OwnerPageTab>
      ) : null,
    [handleChangeTabMode, currentTabMode]
  );

  return (
    <div className={Styles.Tabs}>
      {tabs.map(renderTab)}
      {usingSeisanKanrikun && (
        <OwnersSeisanKanrikunCollaborationTab owner={owner} />
      )}
    </div>
  );
};
