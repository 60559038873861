import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { SeisanKanrikunContract } from '~/models/seisanKanrikunContract';
import { buildUrlWithQuery } from '~/utils/urlQueryConverter';

const paths = {
  index: '/seisan_kanrikun/contracts'
};

export type SearchParams = DeepReadonly<{
  ykContractId?: number;
  title?: string;
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  ykOwnerId?: string;
}>;

export const defaultSearchValue: SearchParams = {
  ykContractId: undefined,
  title: '',
  startDateFrom: '',
  startDateTo: '',
  endDateFrom: '',
  endDateTo: '',
  ykOwnerId: ''
};

type TIndex = (
  params: SearchParams
) => SwrReturnType<ReadonlyArray<SeisanKanrikunContract>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useSeisanKanrikunContracts = (): ReturnType => {
  const useIndex: TIndex = (params: SearchParams) => {
    // オブジェクトのバリューに入力されているものが含まれているものがあるか確認
    const hasTruthyValue = Object.values(params).some((value) => value);

    const { data, mutate, error, isValidating } = useSWR<
      ReadonlyArray<SeisanKanrikunContract>
    >(hasTruthyValue ? buildUrlWithQuery(paths.index, params) : null);
    return {
      data: data || null,
      mutate,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
