import React from 'react';
import { Pagination } from '@itandi/itandi-bb-ui';
import { ContractsListTable } from '~/components/specifics/contracts/components/List/Table';
import { useContracts } from '~/hooks/api/useContracts';
import { useRouter } from 'next/router';
import { convertUrlQueryToContractQuery } from '~/utils/contracts/contractUrlQueryConverter';
import { ContractQuery } from '~/models/contractQuery';
import { TableDisplayCount } from '~/components/common/Table/DisplayCount/index';
import Styles from './index.module.scss';

export const ContractsList: React.FC = () => {
  const router = useRouter();
  const { data: contracts } = useContracts().useIndex();
  const handleChangePage = React.useCallback(
    (newPageNumber: number): void => {
      const query = convertUrlQueryToContractQuery(router.query);
      const newQuery: ContractQuery = { ...query, page: newPageNumber };
      if (newPageNumber === 1) {
        delete newQuery.page;
      }
      router.push({ query: newQuery }).then();
    },
    [router]
  );
  return (
    <div>
      {contracts !== null && (
        <TableDisplayCount
          count={contracts.count}
          currentPage={contracts.currentPage}
          itemsPerPage={30}
        />
      )}
      <ContractsListTable />
      {contracts !== null && (
        <div className={Styles.TablePagination}>
          <Pagination
            currentPageNum={contracts.currentPage}
            handleChangePage={handleChangePage}
            maxPageNum={contracts.sumPageCount}
          />
        </div>
      )}
    </div>
  );
};
