import useSWR from 'swr';
import { SwrReturnType } from '~/models/api';
import { RentParkingSpace } from '~/models/rentParkingSpace';

const paths = {
  index: '/kensakukun/rent_parkings/:parking_property_id/rent_parking_spaces'
};
type TIndex = (
  buildingPropertyId: number | null
) => SwrReturnType<ReadonlyArray<RentParkingSpace>>;
type ReturnType = Readonly<{
  useIndex: TIndex;
}>;

export const useRentParkingSpaces = (): ReturnType => {
  const useIndex: TIndex = (parkingPropertyId: number | null) => {
    const { data, error, isValidating } = useSWR<{
      rentParkingSpaces: ReadonlyArray<RentParkingSpace>;
    }>(
      parkingPropertyId
        ? paths.index.replace(':parking_property_id', String(parkingPropertyId))
        : null
    );
    return {
      data: data ? data.rentParkingSpaces : null,
      isLoading: isValidating,
      error
    };
  };
  return { useIndex };
};
