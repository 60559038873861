import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit, defaultContractValue } from '~/models/contract';
import Styles from './index.module.scss';

export const ContractsEditorSelectedProperty: React.FC = () => {
  const { watch, setValue } = useFormContext<DeepReadonly<ContractForEdit>>();
  const property = watch('property');

  const propertyTypeText = (): string => {
    if (property.propertyType === 'building') {
      return '(一棟)';
    }
    if (property.propertyType === 'parking') {
      return '(駐車場ごと)';
    }
    if (property.propertyType === 'room') {
      return property.roomNumber || '';
    }
    if (property.propertyType === 'parking_space') {
      return property.parkingSpaceNumber || '';
    }
    return '';
  };

  return property.kensakukunPropertyId ? (
    <div className={Styles.Selected}>
      <div className={Styles.SelectedName}>
        {property.name} {propertyTypeText()}
      </div>
      <button
        className={Styles.DeleteLink}
        onClick={(): void => {
          setValue('property', defaultContractValue().property);
        }}
        type="button"
      >
        解除
      </button>
    </div>
  ) : null;
};
