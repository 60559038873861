import React from 'react';
import { Input } from '@itandi/itandi-bb-ui';
import { CustomFieldSetting } from '~/models/customFieldSetting';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';

type Props = DeepReadonly<{
  customFieldSetting: CustomFieldSetting;
}>;

export const ContractsEditorBlockCustomFieldNumber: React.FC<Props> = ({
  customFieldSetting
}: Props) => {
  const { register } = useFormContext<ContractForEdit>();
  return (
    <Input
      type="number"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(`customFieldValues.${customFieldSetting.fieldKey}`)}
    />
  );
};
