import React from 'react';
import { StatusLabelCollaborationLabel } from '~/components/common/StatusLabel/Collaboration/Label';
import { buildSeisanKanrikunContractUrl } from '~/utils/foreignOrigin';
import { useSeisanKanrikunContracts } from '~/hooks/api/useSeisanKanrikunContracts';
import { ContractWithOwners } from '~/models/contract';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ContractsCollaborationSeisanKanrikunModal } from '~/components/specifics/contracts/[id]/components/Collaboration/SeisanKanrikun/Modal';
import { destroy } from '~/utils/api/resources/seisanKanrikunContract';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';

type Props = DeepReadonly<{
  contract: ContractWithOwners;
}>;

export const ContractsCollaborationSeisanKanrikunTab: React.FC<Props> = ({
  contract
}: Props) => {
  const { data: seisanKanrikunContracts, mutate } =
    useSeisanKanrikunContracts().useIndex({ ykContractId: contract.id });

  const collaboratedSeisanKanrikunContract =
    seisanKanrikunContracts !== null && seisanKanrikunContracts.length > 0
      ? seisanKanrikunContracts[0]
      : undefined;

  const { isOpen, handleOpen, handleClose } = useOpenHandler();

  // 連携解除
  const handleRemoveCollaborate = async (contractId: number): Promise<void> => {
    if (!collaboratedSeisanKanrikunContract) {
      return;
    }
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`精算管理くん上の管理委託契約と連携解除して良いですか？`)
    ) {
      const response = await destroy(contractId);
      if (isSuccess(response)) {
        mutate?.();
        toast.success('連携解除に成功しました');
        return;
      }
      toast.error('連携解除に失敗しました');
    }
  };

  const buttonText = (): string => {
    if (contract.archived) return '';
    return collaboratedSeisanKanrikunContract ? '連携解除' : '連携';
  };

  return (
    <>
      <StatusLabelCollaborationLabel
        buttonText={buttonText()}
        externalLink={
          collaboratedSeisanKanrikunContract
            ? buildSeisanKanrikunContractUrl(contract.id)
            : undefined
        }
        onClick={
          collaboratedSeisanKanrikunContract
            ? (): Promise<void> => handleRemoveCollaborate(contract.id)
            : handleOpen
        }
        productKey="sk"
        text={
          collaboratedSeisanKanrikunContract
            ? '管理委託契約連携済み'
            : '管理委託契約未連携'
        }
      />

      <ContractsCollaborationSeisanKanrikunModal
        collaboratedSeisanKanrikunContractMutate={mutate}
        contract={contract}
        handleCollaborationModalClose={handleClose}
        isCollaborationModalOpen={isOpen}
      />
    </>
  );
};
