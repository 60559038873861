import { SwrReturnType } from '~/models/api';
import { ItandiAccountSession } from '~/models/itandiAccountSession';
import useSWR from 'swr';

const paths = {
  show: '/itandi_account_sessions'
};

type TShow = () => SwrReturnType<ItandiAccountSession>;
type ReturnType = Readonly<{
  useShow: TShow;
}>;

export const useItandiAccountSessions = (): ReturnType => {
  const useShow: TShow = () => {
    const { data, mutate, error } = useSWR<ItandiAccountSession>(paths.show);
    return {
      data: data ?? null,
      mutate,
      isLoading: !data && !error,
      error
    };
  };
  return { useShow };
};
