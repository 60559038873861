import { putRequest, deleteRequest } from '~/utils/api/apiHandler';
import { YkResponse } from '~/models/api';
import { SeisanKanrikunOwner } from '~/models/seisanKanrikunOwner';

const paths = {
  update: '/seisan_kanrikun/owners/:id',
  destroy: '/seisan_kanrikun/owners/:id'
};

export const update = async (
  seisanKanrikunOwner: SeisanKanrikunOwner,
  ownerId: number
): Promise<YkResponse> =>
  putRequest(paths.update.replace(':id', String(ownerId)), {
    skOwnerId: seisanKanrikunOwner.id
  });

export const destroy = async (ownerId: number): Promise<YkResponse> =>
  deleteRequest(paths.destroy.replace(':id', String(ownerId)));
