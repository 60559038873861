import React from 'react';
import { RiFileLine } from 'react-icons/ri';
import { useContractFiles } from '~/hooks/api/useContractFiles';
import { ContractWithOwners } from '~/models/contract';
import { ContractFile, ContractFileParams } from '~/models/contractFile';
import { create, destroy } from '~/utils/api/resources/contracts/contractFile';
import { toast } from 'react-toastify';
import { isSuccess } from '~/utils/api/apiHandler';
import { Button } from '@itandi/itandi-bb-ui';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ContractsFilesUploadModal } from '~/components/specifics/contracts/[id]/components/Contents/Files/UploadModal';
import { PATH } from '~/pages/redirect/contracts/[id]/contract_files/[contractFileId]/index';
import { ListCard } from '~/components/common/List/Card';
import { TableContainer } from '~/components/common/Table/Container';
import { TableHead } from '~/components/common/Table/Head';
import { TableRow } from '~/components/common/Table/Row';
import { TableHeader } from '~/components/common/Table/Header';
import { TableBody } from '~/components/common/Table/Body';
import { TableData } from '~/components/common/Table/Data';

type Props = DeepReadonly<{
  contract: ContractWithOwners;
}>;

export const ContractsFilesContents: React.FC<Props> = ({
  contract
}: Props) => {
  const { data: contractFiles, mutate } = useContractFiles().useIndex(
    contract.id
  );
  const [inputs, setInputs] = React.useState<ContractFileParams>({
    title: '',
    file: null
  });

  const { isOpen, handleOpen, handleClose } = useOpenHandler();

  const handleTitleChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputs({
      ...inputs,
      title: e.target.value
    });
  };

  const handleFileChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { files } = e.target;
    if (files !== null) {
      const uploadFile: File = files[0];
      setInputs({
        ...inputs,
        file: uploadFile
      });
    }
  };

  const handleFileUpload = async (): Promise<void> => {
    const response = await create(contract.id, inputs);
    if (isSuccess(response)) {
      mutate?.();
      handleClose();
      toast.success('ファイルアップロードしました');
      return;
    }
    toast.error('ファイルアップロードに失敗しました');
  };

  const handleFileDelete = async (
    contractFile: ContractFile
  ): Promise<void> => {
    // eslint-disable-next-line no-alert
    if (window.confirm('本当にファイル削除しても良いですか？')) {
      const response = await destroy(contractFile);
      if (isSuccess(response)) {
        mutate?.();
        toast.success('ファイルを削除しました');
        return;
      }
      toast.error('ファイル削除に失敗しました');
    }
  };

  return (
    <>
      <ListCard
        buttons={
          !contract.archived && (
            <Button height="small" onClick={handleOpen} variant="secondary">
              追加
            </Button>
          )
        }
        icon={<RiFileLine />}
        title="ファイル"
      >
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableHeader width={35}>タイトル</TableHeader>
              <TableHeader width={50}>ファイル名</TableHeader>
              <TableHeader width={13} />
            </TableRow>
          </TableHead>
          <TableBody>
            {contractFiles !== null &&
              contractFiles.map((contractFile: ContractFile) => (
                <TableRow isInTableBody rowKey={contractFile.id}>
                  <TableData width={35}>{contractFile.title}</TableData>
                  <TableData width={50}>
                    <a
                      href={PATH(contractFile.contractId, contractFile.id)}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {contractFile.fileName}
                    </a>
                  </TableData>
                  <TableData width={13}>
                    {!contract.archived && (
                      <Button
                        height="small"
                        onClick={(): Promise<void> =>
                          handleFileDelete(contractFile)
                        }
                        variant="danger"
                      >
                        削除
                      </Button>
                    )}
                  </TableData>
                </TableRow>
              ))}
          </TableBody>
        </TableContainer>
      </ListCard>
      <ContractsFilesUploadModal
        handleClose={handleClose}
        handleFileChange={handleFileChange}
        handleFileUpload={handleFileUpload}
        handleTitleChange={handleTitleChange}
        inputs={inputs}
        isOpen={isOpen}
      />
    </>
  );
};
