import React from 'react';
import styles from './index.module.scss';

type Props = DeepReadonly<{
  open: boolean;
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}>;

export const Drawer: React.FC<Props> = ({
  open,
  children,
  handleClose
}: Props) =>
  open ? (
    <>
      <button className={styles.Overlay} onClick={handleClose} type="button" />
      <div className={styles.Menu}>{children}</div>
    </>
  ) : null;
