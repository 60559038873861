import React, { ReactNode } from 'react';
import {
  CustomFieldSetting,
  CustomFieldType
} from '~/models/customFieldSetting';
import { RiMoreLine } from 'react-icons/ri';
import { ContractsEditorBlockCustomFieldString } from '~/components/specifics/contracts/components/Editor/Block/CustomField/String';
import { ContractsEditorBlockCustomFieldText } from '~/components/specifics/contracts/components/Editor/Block/CustomField/Text';
import { ContractsEditorBlockCustomFieldDate } from '~/components/specifics/contracts/components/Editor/Block/CustomField/Date';
import { ContractsEditorBlockCustomFieldSelect } from '~/components/specifics/contracts/components/Editor/Block/CustomField/Select';
import { ContractsEditorBlockCustomFieldNumber } from '~/components/specifics/contracts/components/Editor/Block/CustomField/Number';
import { ContractsEditorBlockCustomFieldBoolean } from '~/components/specifics/contracts/components/Editor/Block/CustomField/Boolean';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';

type Props = DeepReadonly<{
  customFieldSettings: CustomFieldSetting[];
}>;
type RowProps = DeepReadonly<{
  customFieldSetting: CustomFieldSetting;
}>;

const InputComponents: {
  [field in CustomFieldType]: React.FC<{
    customFieldSetting: DeepReadonly<CustomFieldSetting>;
  }>;
} = {
  string: ContractsEditorBlockCustomFieldString,
  text: ContractsEditorBlockCustomFieldText,
  number: ContractsEditorBlockCustomFieldNumber,
  select: ContractsEditorBlockCustomFieldSelect,
  boolean: ContractsEditorBlockCustomFieldBoolean,
  date: ContractsEditorBlockCustomFieldDate
};

const CustomFieldBlockRow: React.FC<RowProps> = ({
  customFieldSetting
}: RowProps) => {
  const InputComponent = InputComponents[customFieldSetting.fieldType];
  return (
    <FormBlockRow label={customFieldSetting.name}>
      <InputComponent customFieldSetting={customFieldSetting} />
    </FormBlockRow>
  );
};

export const ContractsEditorBlockCustomField: React.FC<Props> = ({
  customFieldSettings
}: Props) => {
  if (customFieldSettings.length === 0) return null;
  return (
    <FormBlock caption="補足情報" captionIcon={<RiMoreLine />}>
      {customFieldSettings.map(
        (setting: DeepReadonly<CustomFieldSetting>): ReactNode => (
          <CustomFieldBlockRow key={setting.id} customFieldSetting={setting} />
        )
      )}
    </FormBlock>
  );
};
