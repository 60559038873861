import React from 'next';
import { Page as Component } from '~/components/specifics/signup';
import { HeadsYkHead } from '~/components/common/Heads/YkHead';

export const PATH = '/signup';

const Signup: React.FC = () => (
  <>
    <HeadsYkHead title="サインアップ" />
    <Component />
  </>
);

export default Signup;
