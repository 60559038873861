import React from 'react';
import { useRouter } from 'next/router';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { BreadcrumbsItem } from '~/components/common/Breadcrumbs/Item';
import { PATH as ContractsPATH } from '~/pages/contracts';
import { PATH as ContractPATH } from '~/pages/contracts/[id]';
import { ContractsEditor } from '~/components/common/Contracts/Editor';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { useContracts } from '~/hooks/api/useContracts';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';

export const Page: React.FC = () => {
  const router = useRouter();
  const { id } = router.query;
  const { data: contract } = useContracts().useShow(id ? Number(id) : null);

  return (
    <LayoutsItandiSessionLayout>
      <LayoutsContainer
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem href={ContractsPATH}>
              管理委託契約一覧
            </BreadcrumbsItem>
            <BreadcrumbsItem href={ContractPATH(Number(id))}>
              管理委託契約詳細
            </BreadcrumbsItem>
            <BreadcrumbsItem isActive>管理委託契約の更新</BreadcrumbsItem>
          </Breadcrumbs>
        }
        fullBodyWidth
        title="管理委託契約の更新"
      >
        <ContractsEditor contract={contract} editorMode="renewal" />
      </LayoutsContainer>
    </LayoutsItandiSessionLayout>
  );
};
