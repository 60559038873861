import { YkResponse } from '~/models/api';
import { getRequest } from '~/utils/api/apiHandler';

const paths = {
  show: '/contracts/:contract_id/contract_files/:id/file_downloads'
};

export const show = async (
  contractId: number,
  contractFileId: number
): Promise<YkResponse<{ endpoint: string }>> =>
  getRequest(
    paths.show
      .replace(':id', String(contractFileId))
      .replace(':contract_id', String(contractId))
  );
