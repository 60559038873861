import React from 'react';
import { ItandiProductIcon } from '@itandi/itandi-bb-ui';
import { RiExternalLinkLine } from 'react-icons/ri';
import Styles from './index.module.scss';

const productName = {
  sk: '精算管理くん',
  bkk: '物件管理くん',
  ia: '担当者追加'
};

type ProductKey = 'sk' | 'bkk' | 'ia';

type Props = DeepReadonly<{
  text: string;
  productKey: ProductKey;
  onClick?: () => void;
  buttonText?: string;
  externalLink?: string;
}>;

export const StatusLabelCollaborationLabel: React.FC<Props> = ({
  text,
  productKey,
  onClick,
  buttonText,
  externalLink
}: Props) => (
  <div className={Styles.Label}>
    <ItandiProductIcon className={Styles.Icon} productCode={productKey} />
    <div className={Styles.TextsWrapper}>
      <div className={Styles.Text}>{text}</div>
      <div className={Styles.SubText}>{productName[productKey]}</div>
    </div>
    {externalLink && (
      <a
        className={Styles.ExternalLink}
        href={externalLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        <RiExternalLinkLine className={Styles.ExternalLinkIcon} />
      </a>
    )}
    {onClick && buttonText && (
      <button className={Styles.Button} onClick={onClick} type="button">
        {buttonText}
      </button>
    )}
  </div>
);
